import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenesService } from 'src/app/service/examenes.service';
import { DecodeHtmlPipe } from "../../../../../../pipes/decode-html.pipe";
import { FormsModule } from '@angular/forms';
import { SidebarMenuComponent } from "../../../../../common/sidebar-menu/sidebar-menu.component";

@Component({
  selector: 'app-detalle-examen',
  standalone: true,
  imports: [CommonModule, DecodeHtmlPipe, FormsModule, SidebarMenuComponent],
  templateUrl: './detalle-examen.component.html',
  styleUrl: './detalle-examen.component.scss'
})
export class DetalleExamenComponent {
  datosRevision: any;
  currentQuestionIndex: number = 0;
  respuestasUsuario: any = {}; // Guardará las respuestas seleccionadas por el usuario

  constructor(
    private route: ActivatedRoute, private router: Router, 
    private examenesService: ExamenesService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state?.modoRevision) {
      this.datosRevision = navigation.extras.state.datosRevision;
      console.log(this.datosRevision, 'datos detalle')
    }
  }

  ngOnInit(): void {
    if (this.datosRevision?.questions) {
      this.datosRevision.questions.forEach((question: any, index: number) => {
        // Asigna un índice a cada opción de respuesta
        question.answers.forEach((answer: any, answerIndex: number) => {
          answer.index = (answerIndex + 1).toString(); // Asegúrate de que el índice sea una cadena
        });
        // Inicializa respuestas usando el índice de la pregunta
        this.respuestasUsuario[index] = null;
      });
    }
  }
  
  isCorrectAnswer(optionIndex: string, correctAnswer: any): boolean {
    // Verificar si el valor es un string que contiene un array JSON
    if (typeof correctAnswer === 'string' && correctAnswer.startsWith('[')) {
      try {
        // Parsear el string JSON a un array de objetos
        correctAnswer = JSON.parse(correctAnswer);
      } catch (e) {
        console.error("Error al parsear correctAnswer: ", e);
        return false;
      }
    }
  
    // Si la respuesta correcta es una cadena (respuesta única)
    if (typeof correctAnswer === 'string') {
      return optionIndex === correctAnswer;
    }
  
    // Si la respuesta correcta es un array de objetos (múltiples respuestas)
    if (Array.isArray(correctAnswer)) {
      return correctAnswer.some((answerObj: any) => answerObj.answer === optionIndex);
    }
  
    return false;
  }
  

  irAPreguntaSiguiente() {
    if (this.currentQuestionIndex < this.datosRevision?.questions.length - 1) {
      this.currentQuestionIndex++;
    }
  }

  irAPreguntaAnterior() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    }
  }

  formatTime(seconds: number | null | undefined): string {
    if (seconds == null || isNaN(seconds)) {
      return '00:00:00'; // Valor por defecto si no se ha definido el tiempo
    }
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
  
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = secs.toString().padStart(2, '0');
  
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }
  
}

