import { Component, OnInit } from '@angular/core';
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { ExamenesInstructorComponent } from "./examenes-instructor/examenes-instructor.component";
import { ExamenesAdminComponent } from "./examenes-admin/examenes-admin.component";
import { ExamenesAlumnoComponent } from "./examenes-alumno/examenes-alumno.component";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-examenes-dashboard',
  standalone: true,
  imports: [SidebarMenuComponent, ExamenesInstructorComponent, ExamenesAdminComponent, ExamenesAlumnoComponent,
    CommonModule
  ],
  templateUrl: './examenes-dashboard.component.html',
  styleUrl: './examenes-dashboard.component.scss'
})
export class ExamenesDashboardComponent implements OnInit{
  rolUsuario: string;

  constructor(){
    this.rolUsuario = localStorage.getItem('rolUsuario');
  }

  ngOnInit(): void {
  }
}
