import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import { SidebarMenuComponent } from "../../../../../common/sidebar-menu/sidebar-menu.component";
import { ExamenesService } from 'src/app/service/examenes.service';

@Component({
  selector: 'app-resultado-examen',
  standalone: true,
  imports: [CommonModule, SidebarMenuComponent],
  templateUrl: './resultado-examen.component.html',
  styleUrls: ['./resultado-examen.component.scss']
})
export class ResultadoExamenComponent implements OnInit {
  correctAnswersCount: number = 0;
  result: any;
  dataRespuestas: number[] = [];
  currentTime: number
  timeSpentTotal: number
  dataTiempo: number[] = [];
  nombreUsuario:string
  email:string
  idUsuario:any

  constructor(private router: Router, private examenesService: ExamenesService) {
    const navigation = this.router.getCurrentNavigation();
    this.result = navigation?.extras?.state?.result;
    this.currentTime = navigation?.extras?.state?.currentTime;
    this.timeSpentTotal = navigation?.extras?.state?.timeSpentTotal;
    console.log(this.timeSpentTotal, 'timeSpentTotal')
    Chart.register(...registerables);
    this.nombreUsuario = localStorage.getItem('nombreUsuario')
    this.idUsuario = localStorage.getItem('idUsuario');
    this.email = localStorage.getItem('email')
  }

  ngOnInit(): void {
    if (this.result) {
      console.log(this.result, 'result recibido');
      if (this.result.correctAnswerQuestions) {
        try {
          const correctAnswers = this.result.correctAnswerQuestions ? JSON.parse(this.result.correctAnswerQuestions).length : 0;
          const wrongAnswers = this.result.wrongAnswerQuestions ? JSON.parse(this.result.wrongAnswerQuestions).length : 0;
          const notAnswered = this.result.notAnsweredQuestions ? JSON.parse(this.result.notAnsweredQuestions).length : 0;

          this.dataRespuestas = [correctAnswers, wrongAnswers, notAnswered];
          this.correctAnswersCount = correctAnswers;
          console.log(`Cantidad de respuestas correctas: ${this.correctAnswersCount}`);

          const totalTimeSpent = this.timeSpentTotal
          const totalTimePossible = this.currentTime

          const dataTiempo = [totalTimePossible, totalTimeSpent];
          this.dataTiempo = dataTiempo
        } catch (error) {
          console.error('Error al decodificar correctAnswerQuestions:', error);
        }
      }
    }

    this.createCharts(); // Crear el gráfico después de obtener los datos
  }

  createCharts() {
    this.createRespuestasChart();
    this.createTiempoChart();
  }

  createRespuestasChart() {
    const ctx = document.getElementById('respuestasChart') as HTMLCanvasElement;
    new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Correctas', 'Erróneas', 'No respondidas'],
        datasets: [{
          data: this.dataRespuestas, // Usar la propiedad dataRespuestas
          backgroundColor: ['#4caf50', '#f44336', '#9e9e9e'],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              color: '#000'
            }
          }
        }
      }
    });
  }

  createTiempoChart() {
    const ctx = document.getElementById('tiempoChart') as HTMLCanvasElement;
    new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Tiempo Total(sec)', 'Tiempo Consumido(sec)'],
        datasets: [{
          data: this.dataTiempo,
          backgroundColor: ['#03a9f4', '#cddc39'],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              color: '#000'
            }
          }
        }
      }
    });
  }

  sumTimes(timeData: number): number {
    return Object.values(timeData).reduce((total: number, entry: any) => total + parseInt(entry.time_spent), 0);
  }

  verDetalle(resultSlug: string) {
    this.router.navigate(['/mis-estadisticas']);
    // this.examenesService.verDetalle(resultSlug, this.idUsuario).subscribe((datosInicio: any) => {
    //   this.router.navigate(['/detalle-examen', datosInicio.quizSlug], {
    //     state: {
    //       modoRevision: true,
    //       datosRevision: datosInicio // Pasar los datos del detalle del examen
    //     }
    //   });
    // }, error => {
    //   console.error(error);
    // });
  }

}

