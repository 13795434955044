
import { SidebarMenuComponent } from 'src/app/components/common/sidebar-menu/sidebar-menu.component';
import { InicioService } from 'src/app/service/inicio.service';
import { FechasService } from 'src/app/service/util/fechas.service';
import { FilterService } from 'src/app/service/util/filter.service';
import { PaginacionComponent } from "../../../../common/paginacion/paginacion.component";
import { CommonModule } from '@angular/common';
import { PaginatePipe } from "../../../../../pipes/paginate.pipe";
import { ExamenesService } from 'src/app/service/examenes.service';
import { Chart, registerables } from 'chart.js';
import { SortService } from 'src/app/service/util/sort.service';
import { SortingButtonsComponent } from "../../../../common/sorting-buttons/sorting-buttons.component";
import { Router } from '@angular/router';
import { Component, ChangeDetectorRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-mis-estadisticas',
  standalone: true,
  imports: [SidebarMenuComponent, PaginacionComponent, CommonModule, PaginatePipe, SortingButtonsComponent],
  templateUrl: './mis-estadisticas.component.html',
  styleUrls: ['./mis-estadisticas.component.scss']
})
export class MisEstadisticasComponent {
  currentPage: number = 1;
  pageSize: number = 10;
  rolUsuario: string;
  searchTerm: string = '';
  idUsuario: any;
  estadisticasPorExamen: any[] = [];
  estadisticasHistoria: any[] = [];
  activeTab: string = 'examen';
  originalEstadisticasPorExamen: any[] = [];
  originalEstadisticasHistoria: any[] = [];
  isMobile: boolean;

  constructor(
    private inicioService: InicioService,
    private examenesService: ExamenesService,
    public dateService: FechasService,
    private sortService: SortService,
    private filterService: FilterService,
    private router: Router,
    private cdr: ChangeDetectorRef // <--- Agregar ChangeDetectorRef
  ) {
    this.rolUsuario = localStorage.getItem('rolUsuario');
    this.idUsuario = localStorage.getItem('idUsuario');
    this.checkScreenSize(); // Inicializar isMobile basado en el tamaño de la ventana
  }

  // Usar HostListener para escuchar los cambios de tamaño de la ventana
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 767;
    console.log('isMobile:', this.isMobile);
    this.cdr.markForCheck(); // Notificar a Angular que revise los cambios
  }

  ngOnInit(): void {
    Chart.register(...registerables);
    this.obtenerEstadisticasPorExamen();
  }

  obtenerEstadisticasPorExamen() {
    this.examenesService.obtenerEstadisticasPorExamen(this.idUsuario).subscribe((datosInicio: any) => {
      console.log('datos examen', datosInicio)
      this.estadisticasPorExamen = datosInicio;
      this.originalEstadisticasPorExamen = [...datosInicio]; // Guarda la copia original
      this.createIntentosChart(); // Crear el gráfico después de obtener los datos
    }, error => {
      console.error(error);
    });
  }

  toggleExpand(item: any) {
    item.isExpanded = !item.isExpanded;
  }


  verDetalle(item: any) {
    if(item.exam_status === 'fail'){
        return;
    }
    this.examenesService.verDetalle(item.slug, this.idUsuario).subscribe((datosInicio: any) => {
      this.router.navigate(['/detalle-examen', datosInicio.quizSlug], {
        state: {
          modoRevision: true,
          datosRevision: datosInicio // Pasar los datos del detalle del examen
        }
      });
    }, error => {
      console.error(error);
    });
  }

  verAnalisis(datos: any) {
      this.router.navigate(['/analisis-examen', datos.slug], {
        state: {
          resultPuntuaciones:{
            aciertos: datos.aciertosParaAnalisis,
            errores: datos.errores,
            noRespondidas: datos.noRespondidas,
            totalPreguntas: datos.totalQuestions,
            titulo: datos.title
          },
          resultTiempo:{
            invertidoEnErroneo: datos.tiempoInvertidoEnErroneas,
            invertidoEnCorrecto: datos.tiempoInvertidoEnCorrectas,
            tiempoTotal: datos.tiempoTotal,
            tiempoInvertido: datos.tiempoInvertido,
            titulo: datos.title
          }
        }
      });
  }

  obtenerEstadisticasHistoria() {
    this.examenesService.obtenerEstadisticasHistoria(this.idUsuario).subscribe((datosInicio: any) => {
      console.log(datosInicio, 'datos')
      this.estadisticasHistoria = datosInicio.map((item: any) => {
        const correctAnswersArray = JSON.parse(item.correctAnswers);
        const wrongAnswersArray = JSON.parse(item.wrongAnswers);
        const aciertos = correctAnswersArray.length;
        const errores = wrongAnswersArray.length
        const totalQuestions = item.totalQuestions;
        const noRespondidas = totalQuestions - aciertos - errores
        const percentage = item.percentage;
        return {
          ...item,
          aciertos: `${aciertos}/${totalQuestions}`,
          correctAnswersPercentage: percentage,
          errores: errores,
          noRespondidas: noRespondidas,
          aciertosParaAnalisis: aciertos
        };
      });
      this.originalEstadisticasHistoria = [...this.estadisticasHistoria]; // Guarda la copia original
      this.createHistoriaChart();
    }, error => {
      console.error(error);
    });
  }

  generarCertificado(item: any) {
    if(item.exam_status === 'fail'){
        return;
    }
    this.examenesService.generarCertificado(item.slug).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'certificate.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }


  createIntentosChart() {
    const ctx = document.getElementById('intentosChart') as HTMLCanvasElement;
    const labels = this.estadisticasPorExamen.map(item => `${item.nombreCurso} (${item.attempts})`);
    const data = this.estadisticasPorExamen.map(item => item.attempts);

    new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: this.generateRandomColors(data.length),
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              color: '#000'
            }
          }
        }
      }
    });
  }

  createHistoriaChart() {
    const ctx = document.getElementById('historiaChart') as HTMLCanvasElement;

    const orderedData: { percentage: number, titles: string[] }[] = [];

    this.estadisticasHistoria.forEach(item => {
      const percentage = parseInt(item.correctAnswersPercentage, 10);
      const existingEntry = orderedData.find(entry => entry.percentage === percentage);

      if (existingEntry) {
        existingEntry.titles.push(item.title);
      } else {
        orderedData.push({
          percentage: percentage,
          titles: [item.title]
        });
      }
    });

    const data = orderedData.map(entry => entry.percentage);
    const labels = orderedData.map(entry => entry.titles);

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Calificaciones',
          data: data,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
          pointRadius: 5,
          pointHoverRadius: 7,
          pointBackgroundColor: 'rgba(75, 192, 192, 1)',
          pointHoverBackgroundColor: 'rgba(255, 99, 132, 1)',
          pointBorderColor: '#fff',
          pointHoverBorderColor: '#fff'
        }]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: false,
            min: 20,
            max: 100,
            ticks: {
              stepSize: 10,
            },
            title: {
              display: true,
              text: 'Calificación'
            }
          },
          x: {
            display: false,
            title: {
              display: true,
              text: 'Exámenes'
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: () => '', // Deshabilita el título del tooltip
              label: (tooltipItem: any) => {
                const percentage = tooltipItem.raw;
                const examTitles = labels[tooltipItem.dataIndex].join(', ');
                return `${percentage}%: ${examTitles}`; // Muestra el porcentaje y el título del examen
              }
            }
          },
          legend: {
            position: 'top',
            labels: {
              color: '#000'
            }
          }
        }
      }
    });
  }
  generateRandomColors(numColors: number): string[] {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      colors.push(color);
    }
    return colors;
  }

  toggleView(view: string) {
    this.activeTab = view;
    this.currentPage = 1;
    if (view === 'historia' && this.estadisticasHistoria.length === 0) {
      this.obtenerEstadisticasHistoria();
    } else if (view === 'examen' && this.estadisticasPorExamen.length === 0) {
      this.obtenerEstadisticasPorExamen();
    }
  }

  filterEstadisticas(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.toLowerCase();

    if (this.activeTab === 'examen') {
      this.estadisticasPorExamen = this.originalEstadisticasPorExamen.filter(examen =>
        examen.nombreCurso.toLowerCase().includes(this.searchTerm)
      );
    } else {
      this.estadisticasHistoria = this.originalEstadisticasHistoria.filter(examen =>
        examen.title.toLowerCase().includes(this.searchTerm)
      );
    }

    this.currentPage = 1;
  }

  sortEstadisticas(prop: string, order: "asc" | "desc" | null) {
    if(this.activeTab === 'examen'){
      if (order === null) {
        return;
      }
      if(this.originalEstadisticasPorExamen.length === this.estadisticasPorExamen.length){
        this.estadisticasPorExamen = this.sortService.sort(this.originalEstadisticasPorExamen, prop, order);
      }else{
        this.estadisticasPorExamen = this.sortService.sort(this.estadisticasPorExamen, prop, order);
      }
      this.estadisticasPorExamen = [...this.estadisticasPorExamen];
    }else{
      if (order === null) {
        return;
      }
      if(this.originalEstadisticasHistoria.length === this.estadisticasHistoria.length){
        this.estadisticasHistoria = this.sortService.sort(this.originalEstadisticasHistoria, prop, order);
      }else{
        this.estadisticasHistoria = this.sortService.sort(this.estadisticasHistoria, prop, order);
      }
      this.estadisticasHistoria = [...this.estadisticasHistoria];
    }

  }

  getCurrentData() {
    return this.activeTab === 'examen' ? this.estadisticasPorExamen : this.estadisticasHistoria;
  }

  changePageSize(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage;
  }
}



