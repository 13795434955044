<app-sidebar-menu></app-sidebar-menu>

<!-- <div class="page-title-area item-bg4">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Events</li>
            </ul>
            <h2>Events</h2>
        </div>
    </div>
</div> -->

<div
    class="events-area pt-100 pb-70"
    [class.dark-events-area]="themeService.isDark()"
>
    <div class="container">
        <div *ngIf="showAlert" class="custom-alert">
            <i class='bx bx-info-circle'></i>
            <div class="alert-text">
                La biblioteca se usa de manera opcional y es complementaria a tu formación, el contenido aquí compartido pertenece a sus respectivos autores.
            </div>
            <button type="button" class="close-btn" aria-label="Close" (click)="closeAlert()">✖</button>
        </div>
        <div class="topbar-result-count" >
            <p>Mostrando del {{ getCurrentRangeStart() }} al 
                {{ getCurrentRangeEnd() }} de {{ filteredTalleres.length > 0? filteredTalleres.length : bibliotecas.length }} Talleres</p>
        </div>
        <div class="mx-auto">
            <div class="topbar-ordering-and-search w-100">
                <div class="row align-items-center w-100">
                    <div class="topbar-container">
                        <div class="topbar-select">
                            <label>Selecciona un orden:</label>
                            <select class="order-select" [(ngModel)]="currentOrder" (change)="ordenarTalleres($event.target.value)">
                                <option value="recientes">Más recientes</option>
                                <option value="mayorDuracion">Mayor duración</option>
                                <option value="menorDuracion">Menor duración</option>
                            </select>
                        </div>
                        <div class="topbar-select">
                            <label>Selecciona un idioma:</label>
                            <select class="order-select" [(ngModel)]="currentLanguage" (change)="filtrarTalleresPorIdioma($event.target.value)">
                                <option value="all">Todos</option>
                                <option value="Espanol">Español</option>
                                <option value="Ingles">Ingles</option>
                            </select>
                        </div>                        
                        <div class="topbar-search">
                            <form>
                                <div class="input-group">
                                    <label><i class="bx bx-search"></i></label>
                                    <input type="text" class="input-search" placeholder="Buscar aquí..."
                                     [(ngModel)]="searchText" name="search" (keyup)="onSearchTextChange($event)"
                                     style="width: 400px;">
                                </div>
                            </form>
                        </div>
                        <div class="topbar-clear-filters">
                            <button class="btn btn-sm btn-danger" type="button" (click)="clearFilters()">
                                <i class="bx bx-reset"></i> Limpiar filtros
                            </button>
                        </div>
                    </div>
                </div>
                <h3 style="font-size: 20px; margin-bottom: 20px;">Talleres</h3>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box mb-30" *ngFor="let item of paginatedTalleres">
                <div class="events-image">
                    <div>
                        <img src="{{item.urlImagen}}" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3><a routerLink="/single-events">{{item.nombre}}</a></h3>
                        <p>{{item.descripcion}}</p>
                        <span class="location">
                            <i class='bx bx-time-five'></i>Duracion: {{item.duracion/60/60|number: '.1-2'}} hrs
                        </span>
                        <a [routerLink]="['/biblioteca/detalles/',item.idRepositorio,item.url]" class="join-now-btn">Ver Taller</a>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>Ultima Actualizacion</span>
                                <p>{{ formatearFechaConHora(item.fechaHora) }}</p>
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="paginatedTalleres.length > 0">
        <div class="pagination-area text-center">
            <a *ngFor="let page of getVisiblePages(); let i = index"
                (click)="page !== '...' ? goToPage(page) : null"
                class="page-numbers"
                [class.current]="currentPage === page"
                [class.disabled]="page === '...'">
                {{page}}
            </a>
            <a (click)="goToPage(currentPage + 1)"
                class="page-numbers next"
                [class.disabled]="currentPage === getTotalPages()">
                <i class='bx bx-chevron-right'></i>
            </a>
        </div>
    </div>    
</div>

<!-- MODAL ACCESO -->
<div *ngIf="bloquearAcceso" class="modal-overlay">
    <div class="contenido-modal">
        <div class="titulo-modal">
            <h5>Acceso Finalizado</h5>
        </div>
        <div class="cuerpo-modal">
            <p>Tu periodo de acceso a la biblioteca ha finalizado. Cuando te inscribas al siguiente curso podrás ingresar nuevamente.</p>
        </div>
        <div class="boton-modal">
            <button type="button" class="btn btn-secondary" [routerLink]="['/']" routerLinkActive="router-link-active">
                <i class="bx bx-hide"></i> Cerrar
            </button>
        </div>
    </div>
</div>

  
  
