<app-sidebar-menu></app-sidebar-menu>
<div class="container">
    <div class="order-buttons-container">
      <div class="btn-group" role="group" aria-label="Toggle buttons">
        <button type="button" class="btn" [ngClass]="activeTab === 'puntuaciones' ? 'btn-primary' : 'btn-secondary'"
          (click)="toggleView('puntuaciones')">
          Puntuaciones
        </button>
        <button type="button" class="btn" [ngClass]="activeTab === 'tiempo' ? 'btn-primary' : 'btn-secondary'"
          (click)="toggleView('tiempo')">
          Tiempo
        </button>
      </div>
    </div>
  
    <div class="recent-orders-table" *ngIf="activeTab === 'puntuaciones'">
      <table class="table table-bordered">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Titulo</th>
                    <th>Correcta</th>
                    <th>Erronea</th>
                    <th>No respondidas</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{resultPuntuaciones.titulo}}</td>
                    <td>{{resultPuntuaciones.aciertos}}</td>
                    <td>{{resultPuntuaciones.errores}}</td>
                    <td>{{resultPuntuaciones.noRespondidas}}</td>
                    <td>{{resultPuntuaciones.totalPreguntas}}</td>
                </tr>
            </tbody>
        </table>

      </table>
      <div class="charts-container">
        <div class="chart">
          <canvas id="puntuacionesChart"></canvas>
        </div>
      </div>
    </div>
  
    <div class="recent-orders-table" *ngIf="activeTab === 'tiempo'">
      <table class="table table-bordered">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Titulo</th>
                    <th>Invertido en correcto</th>
                    <th>Invertido en erroneo</th>
                    <th>Tiempo total</th>
                    <th>Tiempo invertido</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{resultTiempo.titulo}}</td>
                    <td>{{formatTime(resultTiempo.invertidoEnCorrecto)}}</td>
                    <td>{{formatTime(resultTiempo.invertidoEnErroneo)}}</td>
                    <td>{{formatTime(resultTiempo.tiempoTotal)}}</td>
                    <td>{{formatTime(resultTiempo.tiempoInvertido)}}</td>
                </tr>
            </tbody>
        </table>

      </table>
      <div class="charts-container">
        <div class="chart">
          <canvas id="tiempoChart"></canvas>
        </div>
      </div>
    </div>
  </div>
  
