import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExamenesService } from 'src/app/service/examenes.service';
import { PaginacionComponent } from "../../../common/paginacion/paginacion.component";
import { PaginatePipe } from "../../../../pipes/paginate.pipe";
import { BsModalService } from 'ngx-bootstrap/modal';
import { CursosService } from 'src/app/service/cursos.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { QuizCategory } from 'src/app/interface/categoria';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-examenes-admin',
  standalone: true,
  imports: [CommonModule, PaginacionComponent, PaginatePipe, FormsModule, ReactiveFormsModule],
  templateUrl: './examenes-admin.component.html',
  styleUrl: './examenes-admin.component.scss',
  providers:[BsModalService, ToastrService]
})
export class ExamenesAdminComponent implements OnInit{

  @Input() columns: { key: string, title: string }[] = []; // Recibe columnas dinámicamente
  @Input() data: any[] = []; // Recibe data dinámica desde el componente padre
  currentPage: number = 1;
  pageSize: number = 10;
  @Input() title: string;
  @Input() buttonText: string;

  @Output() onPageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onPrimaryAction: EventEmitter<number> = new EventEmitter<any>();
  @Output() onSecondaryAction: EventEmitter<number> = new EventEmitter<any>();
  @Output() onOpenModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMenuToggle: EventEmitter<boolean> = new EventEmitter<boolean>(); // Evento para el menú
  isMenuOpen: { [key: number]: boolean } = {}; 

  ngOnInit(): void {
    // Inicializa isMenuOpen en falso para cada item si es necesario
    this.data.forEach(item => {
     this.isMenuOpen[item.id] = false; // O usa un identificador único
   });
 }

 toggleMenu(itemId: number, event: MouseEvent) {
  event.stopPropagation(); // Evita que el evento cierre inmediatamente el menú
  const menuElement = document.querySelector(`#dropdown-menu-${itemId}`);

  if (menuElement) {
    const rect = menuElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Si el menú está demasiado cerca del borde inferior, ajusta su posición
    if (rect.bottom > windowHeight) {
      menuElement.classList.add('dropdown-menu-top'); // Agrega clase para mostrar el menú hacia arriba
    } else {
      menuElement.classList.remove('dropdown-menu-top');
    }
  }

  // Cierra los demás menús
  Object.keys(this.isMenuOpen).forEach(key => {
    if (+key !== itemId) {
      this.isMenuOpen[key] = false;
    }
  });

  // Alterna el menú del item seleccionado
  this.isMenuOpen[itemId] = !this.isMenuOpen[itemId];
}

  closeMenu(itemId: number) {
    this.isMenuOpen[itemId] = false; // Cierra el menú al salir
  }
  
  primaryAction(param: any) {
    this.onPrimaryAction.emit(param);
  }
  
  secondaryAction(param: any) {
    this.onSecondaryAction.emit(param);
  }

  onEdit(item: any) {
    this.onOpenModal.emit(item);
  }

  filterChange(event: Event) {
    this.onFilterChange.emit(event);
  }

  changePageSize(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
  }

  changePage(newPage: number) {
    this.currentPage = newPage;
  }
}
