import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenRequest } from 'src/app/interface/examen';
import { DecodeHtmlPipe } from 'src/app/pipes/decode-html.pipe';
import { ExamenesService } from 'src/app/service/examenes.service';

@Component({
  selector: 'app-hacer-examen',
  standalone: true,
  imports: [CommonModule, DecodeHtmlPipe, FormsModule],
  templateUrl: './hacer-examen.component.html',
  styleUrls: ['./hacer-examen.component.scss']
})
export class HacerExamenComponent implements OnInit, OnDestroy {
  instrucciones: any;
  slug: string | undefined;
  terminosAceptados: boolean = false;
  mostrarExamen: boolean = false;
  currentQuestionIndex: number = 0;
  respuestasUsuario: { [questionId: number]: string[] } = {};
  tiemposPorPregunta: { [questionId: number]: any } = {};
  tiemposInicio: { [questionId: number]: number } = {};
  idUsuario: any;
  tiempoRestante: number = 0;
  minutos: number = 0;
  segundos: number = 0;
  intervalo: any;
  estadoPreguntas: { [questionId: number]: string } = {};
  currentDuration: number;
  timeSpentInSeconds: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private examenesService: ExamenesService
  ) {
    this.idUsuario = localStorage.getItem('idUsuario');
    const navigation = this.router.getCurrentNavigation();
    this.currentDuration = navigation?.extras?.state?.duration || 0;
    this.tiempoRestante = this.currentDuration * 60;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.slug = params.get('slug') || undefined;
      if (this.slug) {
        this.obtenerInstruccionesYPreguntas(this.slug);
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalo);
  }

  obtenerInstruccionesYPreguntas(slug: string) {
    this.examenesService.obtenerInstruccionesYPreguntas(slug).subscribe(datosInicio => {
      this.instrucciones = datosInicio;
      this.instrucciones.questions.forEach((question: any) => {
        const decodedAnswers = JSON.parse(question.answers);
        question.answers = decodedAnswers.map((answer: any, index: number) => ({
          option_index: (index + 1).toString(),
          option_value: answer
        }));
      });
    }, error => {
      console.error(error);
    });
  }

  aceptarTerminos() {
    this.terminosAceptados = !this.terminosAceptados;
  }

  comenzarExamen() {
    this.mostrarExamen = !this.mostrarExamen;
    this.iniciarTemporizador();
    this.registrarInicioPregunta(); // Registrar el inicio cuando se comienza el examen
  }

  iniciarTemporizador() {
    this.intervalo = setInterval(() => {
      if (this.tiempoRestante > 0) {
        this.tiempoRestante--;
        this.minutos = Math.floor(this.tiempoRestante / 60);
        this.segundos = this.tiempoRestante % 60;
      } else {
        this.terminarExamen();
        clearInterval(this.intervalo);
      }
    }, 1000);
  }

  registrarInicioPregunta() {
    const questionId = this.instrucciones.questions[this.currentQuestionIndex]?.id;
    if (questionId) {
      this.tiemposInicio[questionId] = this.currentDuration * 60 - this.tiempoRestante;
    }
  }

  registrarTiempoPregunta() {
    const questionId = this.instrucciones.questions[this.currentQuestionIndex]?.id;
    if (questionId) {
      const tiempoActual = this.currentDuration * 60 - this.tiempoRestante;
      if (this.tiemposInicio[questionId]) {
        this.tiemposPorPregunta[questionId] = tiempoActual - this.tiemposInicio[questionId];
      }
    }
  }

  marcarParaRevisarYAvanzar() {
   // this.recogerRespuestas();
    const questionId = this.instrucciones.questions[this.currentQuestionIndex]?.id;
    if (questionId) {
      this.estadoPreguntas[questionId] = 'markedForReview';
    }
    if (this.currentQuestionIndex < (this.instrucciones?.questions.length || 0) - 1) {
      this.currentQuestionIndex++;
    }
  }

  limpiarRespuesta() {
    const questionId = this.instrucciones.questions[this.currentQuestionIndex]?.id;
    if (questionId) {
      delete this.respuestasUsuario[questionId];
    }
  }

  actualizarEstadoPregunta(questionId: number) {
    if (this.respuestasUsuario[questionId] != null) {
      this.estadoPreguntas[questionId] = 'answeredCorrectly';
    } else {
      this.estadoPreguntas[questionId] = 'notAnswered';
    }
  }

  irAPreguntaSiguiente() {
    this.registrarTiempoPregunta();
    const questionId = this.instrucciones.questions[this.currentQuestionIndex]?.id;
    if (questionId) {
      this.actualizarEstadoPregunta(questionId);
    }
    if (this.currentQuestionIndex < (this.instrucciones?.questions.length || 0) - 1) {
      this.currentQuestionIndex++;
      this.registrarInicioPregunta();
    }
  }

  seleccionarOpcion($event: Event) {
    const target = $event.target as HTMLInputElement;
    const questionId = this.instrucciones.questions[this.currentQuestionIndex]?.id;
    console.log('respuestas al entrar', this.respuestasUsuario)
    // Inicializa el arreglo de respuestas para la pregunta si aún no existe
    if (!this.respuestasUsuario[questionId]) {
        this.respuestasUsuario[questionId] = [];
    }

    const answerValue = target.value? target.value: null;
    const index = this.respuestasUsuario[questionId].indexOf(answerValue);

    if (target.checked && index === -1) {
        // Agregar respuesta seleccionada
        this.respuestasUsuario[questionId].push(answerValue);
    } else if (!target.checked && index !== -1) {
        // Eliminar respuesta deseleccionada
        this.respuestasUsuario[questionId].splice(index, 1);
    }
    console.log(this.respuestasUsuario, 'respuestas final')
}


  irAPreguntaAnterior() {
    this.registrarTiempoPregunta();
    const questionId = this.instrucciones.questions[this.currentQuestionIndex]?.id;
    if (questionId) {
      this.actualizarEstadoPregunta(questionId);
    }
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      this.registrarInicioPregunta();
    }
  }

  irAPregunta(index: number) {
    this.registrarTiempoPregunta();
    const questionId = this.instrucciones.questions[this.currentQuestionIndex]?.id;
    if (questionId) {
      this.actualizarEstadoPregunta(questionId);
    }
    this.currentQuestionIndex = index;
    this.registrarInicioPregunta();
  }

  // recogerRespuestas() {
  //   this.instrucciones?.questions.forEach((question: any) => {
  //     const selectedAnswers = Array.from(document.querySelectorAll(`input[name="answer-${question.id}"]:checked`)) as HTMLInputElement[];
  //     if (selectedAnswers.length > 0) {
  //       this.respuestasUsuario[question.id] = selectedAnswers.map(answer => answer.value);
  //     } else {
  //       this.respuestasUsuario[question.id] = null;
  //     }
  //   });
  // }

  terminarExamen() {
    this.registrarTiempoPregunta(); // Registrar el tiempo de la última pregunta
    clearInterval(this.intervalo);
   // this.recogerRespuestas();

    if (this.slug) {
      const request: ExamenRequest = {
        inputData: this.instrucciones.questions.reduce((acc: any, question: any) => {
          const questionId = question.id;
          const userAnswers = this.respuestasUsuario[questionId] || null;
          acc[questionId] = userAnswers ? userAnswers : [];
          return acc;
        }, {}),
        timePerQuestion: this.tiemposPorPregunta,
        timeSpent: (this.currentDuration * 60) - this.tiempoRestante
      };
      this.timeSpentInSeconds = request.timeSpent;
      console.log(request, 'tiempos')
      this.examenesService.finalizarExamen(this.slug, request, this.idUsuario).subscribe(result => {
        const currentTime = this.currentDuration * 60
        const timeSpentTotal = this.timeSpentInSeconds
        console.log(result, 'res')
        this.router.navigate(['/resultado-examen'], { state: { result, currentTime, timeSpentTotal } });
      }, error => {
        console.error('Error al guardar resultados:', error);
      });
    }
  }

  get cantidadRespondidas(): number {
    return Object.values(this.estadoPreguntas).filter(status => status === 'answeredCorrectly').length;
  }

  get cantidadNoRespondidas(): number {
    return Object.values(this.estadoPreguntas).filter(status => status === 'notAnswered').length;
  }

  get cantidadMarcadas(): number {
    return Object.values(this.estadoPreguntas).filter(status => status === 'markedForReview').length;
  }

  get cantidadNoVisitadas(): number {
    return this.instrucciones?.questions.length - (this.cantidadRespondidas + this.cantidadNoRespondidas + this.cantidadMarcadas);
  }
}



  

