import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ExamenesService } from 'src/app/service/examenes.service';
import { CommonModule } from '@angular/common';
import { Chart, registerables } from 'chart.js';
import { SidebarMenuComponent } from "../../../../../common/sidebar-menu/sidebar-menu.component";

@Component({
  selector: 'app-analisis-examen',
  standalone: true,
  imports: [CommonModule, SidebarMenuComponent],
  templateUrl: './analisis-examen.component.html',
  styleUrl: './analisis-examen.component.scss'
})
export class AnalisisExamenComponent {
  correctAnswersCount: number = 0;
  resultPuntuaciones: any;
  resultTiempo: any;
  dataPuntuaciones: number[] = [];
  currentTime: number;
  timeSpentTotal: number;
  dataTiempo: number[] = [];
  nombreUsuario: string;
  email: string;
  idUsuario: any;
  activeTab: string = 'puntuaciones';
  puntuacionesChartInstance: any;
  tiempoChartInstance: any;

  constructor(private router: Router, private examenesService: ExamenesService) {
    const navigation = this.router.getCurrentNavigation();
    this.resultPuntuaciones = navigation?.extras?.state?.resultPuntuaciones;
    this.resultTiempo = navigation?.extras?.state?.resultTiempo;
    this.currentTime = navigation?.extras?.state?.currentTime;
    this.timeSpentTotal = navigation?.extras?.state?.timeSpentTotal;
    Chart.register(...registerables);
    this.nombreUsuario = localStorage.getItem('nombreUsuario');
    this.idUsuario = localStorage.getItem('idUsuario');
    this.email = localStorage.getItem('email');
  }

  ngOnInit(): void {
    this.dataPuntuaciones = [this.resultPuntuaciones.aciertos, this.resultPuntuaciones.errores, 
    this.resultPuntuaciones.noRespondidas];
    this.dataTiempo = [this.resultTiempo.tiempoTotal, this.resultTiempo.tiempoInvertido];
    this.createPuntuacionesChart();
  }

  toggleView(tab: string) {
    this.activeTab = tab;
    setTimeout(() => {
      if (this.activeTab === 'puntuaciones') {
        this.destroyTiempoChart();
        this.createPuntuacionesChart();
      } else if (this.activeTab === 'tiempo') {
        this.destroyPuntuacionesChart();
        this.createTiempoChart();
      }
    }, 0);  // Aseguramos que el DOM esté listo antes de crear el gráfico.
  }

  ngAfterViewChecked() {
    if (this.activeTab === 'puntuaciones' && !this.puntuacionesChartInstance) {
      this.createPuntuacionesChart();
    } else if (this.activeTab === 'tiempo' && !this.tiempoChartInstance) {
      this.createTiempoChart();
    }
  }

  createPuntuacionesChart() {
    const canvas = document.getElementById('puntuacionesChart') as HTMLCanvasElement;
    if (canvas && !this.puntuacionesChartInstance) {
      this.puntuacionesChartInstance = new Chart(canvas, {
        type: 'doughnut',
        data: {
          labels: ['Correctas', 'Erróneas', 'No respondidas'],
          datasets: [{
            data: this.dataPuntuaciones,
            backgroundColor: ['#4caf50', '#f44336', '#9e9e9e'],
          }]
        },
        options: {
          responsive: true,
        }
      });
    }
  }

  createTiempoChart() {
    const canvas = document.getElementById('tiempoChart') as HTMLCanvasElement;
    if (canvas && !this.tiempoChartInstance) {
      this.tiempoChartInstance = new Chart(canvas, {
        type: 'doughnut',
        data: {
          labels: ['Tiempo Total(sec)', 'Tiempo Consumido(sec)'],
          datasets: [{
            data: this.dataTiempo,
            backgroundColor: ['#03a9f4', '#cddc39'],
          }]
        },
        options: {
          responsive: true,
        }
      });
    }
  }

  destroyPuntuacionesChart() {
    if (this.puntuacionesChartInstance) {
      this.puntuacionesChartInstance.destroy();
      this.puntuacionesChartInstance = null;
    }
  }

  destroyTiempoChart() {
    if (this.tiempoChartInstance) {
      this.tiempoChartInstance.destroy();
      this.tiempoChartInstance = null;
    }
  }

  formatTime(seconds: number | null | undefined): string {
    if (seconds == null || isNaN(seconds)) {
      return '00:00:00'; // Valor por defecto si no se ha definido el tiempo
    }
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
  
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = secs.toString().padStart(2, '0');
  
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }
}

