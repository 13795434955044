import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExamenesService } from 'src/app/service/examenes.service';
import { PaginacionComponent } from "../../../../common/paginacion/paginacion.component";
import { CommonModule } from '@angular/common';
import { PaginatePipe } from "../../../../../pipes/paginate.pipe";
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { Router } from '@angular/router';
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";
import { DynamicModalComponent } from "../../../../common/dynamic-modal/dynamic-modal.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-banco-preguntas',
  standalone: true,
  imports: [PaginacionComponent, CommonModule, PaginatePipe, ExamenesAdminComponent, SidebarMenuComponent, DynamicModalComponent],
  templateUrl: './banco-preguntas.component.html',
  styleUrl: './banco-preguntas.component.scss',
  providers: [BsModalService, ToastrService]
})
export class BancoPreguntasComponent implements OnInit {
  columns = [
    { key: 'name', title: 'Materia' },
    { key: 'subjectCode', title: 'Codigo' },
    { key: 'actions', title: 'Acciones', type: 'actions', buttonOne: 'Ver Preguntas', buttonTwo: null }
  ];

  materias: any[] = []
  originalMaterias: any[] = []
  pageSize: number = 10
  currentPage: number = 1
  searchTerm: string = ''

  // MODAL CREAR MATERIA
  modalTitle: string = 'Agregar Materia'
  modalFields: any[] = []
  originalModalFields: any[] = []
  modalRef: BsModalRef
  initialData: any = null
  @ViewChild('dynamicModal') dynamicModalTemplate: TemplateRef<any>;
  esMateria: boolean
  idMateriaGuardar: number
  currentFieldValues: { fieldName: string, value: any }[] = [];

  //MODAL AGREGAR PREGUNTA
  temasPorMateria:any[] = []
  typeQuestionOptions = [{
    id: 1,
    label: 'Respuesta simple'
  },
  {
    id: 2,
    label: 'Opcion multiple'
  },
  {
    id: 3,
    label: 'Completar frase'
  },
  {
    id: 4,
    label: 'Unir preguntas con respuesta'
  },
  {
    id: 5,
    label: 'Parrafo'
  },
  {
    id: 6,
    label: 'Video'
  },
  {
    id: 7,
    label: 'Audio'
  }]

  totalOptionsAndAnswers = [{
    id: 1,
    label: 1
  },
  {
    id: 2,
    label: 2
  },
  {
    id: 3,
    label: 3
  },
  {
    id: 4,
    label: 4
  },
  {
    id: 5,
    label: 5
  },
  {
    id: 6,
    label: 6
  },
  {
    id: 7,
    label: 7
  },
  {
    id: 8,
    label: 8
  },
  {
    id: 9,
    label: 9
  }

  ]

  //MODAL AGREGAR PREGUNTA
  difficultyLevelOptions = [{
    id: 1,
    label: 'Facil'
  },
  {
    id: 2,
    label: 'Media'
  },
  {
    id: 3,
    label: 'Dificil'
  }]

  constructor(private examenesService: ExamenesService, private router: Router,
    private modalService: BsModalService, private toastrService: ToastrService) {
  }
  ngOnInit(): void {
    this.obtenerPreguntas()
  }

  obtenerPreguntas() {
    this.examenesService.obtenerPreguntasTabla().subscribe(
      (response: any[] = []) => {
        console.log(response, 'materias')
        this.materias = response
        this.originalMaterias = [...response];
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  obtenerTemasPorMateria(subjectId:any) {
    this.examenesService.obtenerTemasPorMateria(subjectId).subscribe(
      (response: any[] = []) => {
        console.log(response, 'temas')
        this.temasPorMateria = response
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  abrirModal(datos: any) {
    if (datos === null) {
      this.esMateria = true
      this.modalTitle = datos ? 'Editar Materia' : 'Nueva Materia';
      this.modalFields = [
        {
          name: 'title', type: 'text', label: 'Titulo de Materia*',
          required: true, errorMessage: 'El titulo es obligatorio.'
        },
        {
          name: 'code', type: 'textarea', label: 'Codigo de Materia*',
          required: true, errorMessage: 'El codigo es obligatorio.'
        }
      ];
    } else {
      this.esMateria = false
      this.modalTitle = 'Cargar Pregunta';
      this.idMateriaGuardar = datos ? datos.id : null
      this.obtenerTemasPorMateria(datos.id)
      this.modalFields = [
        {
          name: 'topic', type: 'select', label: 'Tema*',
          required: true, errorMessage: 'El tema es obligatorio.',  
          value: this.temasPorMateria.length > 0 ? this.temasPorMateria[0].id : null,
          options: this.temasPorMateria.map(option => ({ value: option.id, label: option.topicName }))
        },
        {
          name: 'question', type: 'editor', label: 'Pregunta*',
          required: true, errorMessage: 'La pregunta es obligatoria.',
        },
        {
          name: 'questionType', type: 'select', label: 'Tipo de pregunta*', change: true,
          required: true, errorMessage: 'El tipo de pregunta es obligatorio.',
          options: this.typeQuestionOptions.map(option => ({ value: option.id, label: option.label }))
        },
        {
          name: 'file', type: 'file', label: 'Cargar', value: null, required:false
        },
        {
          name: 'difficulty', type: 'select', label: 'Nivel de dificultad*',
          required: true, errorMessage: 'El nivel de dificultad es obligatorio..',
          options: this.difficultyLevelOptions.map(option => ({ value: option.id, label: option.label }))
        },
        {
          name: 'tips', type: 'text', label: 'Tips',
          placeholder: 'Sugerencia para la pregunta', required:false
        },
        {
          name: 'description', type: 'editor', label: 'Explicacion', required:false
        },
        {
          name: 'percentage', type: 'number', label: 'Puntuaciones*',
          required: true, errorMessage: 'La puntuacion es obligatoria.', value: 1
        },
        {
          name: 'time', type: 'text', label: 'Tiempo a invertir*',
          required: true, errorMessage: 'El tiempo a invertir es obligatorio.'
        },
      ];
    }
    this.originalModalFields = [...this.modalFields]
    this.initialData = datos ? this.modalFields : null;
    this.modalRef = this.modalService.show(this.dynamicModalTemplate, { class: !this.esMateria ? 'modal-lg' : 'modal-md' });
  }

  handleQuestionTypeChange(event: any) {
    const selectedType = parseInt(event.selectedValue, 10);
    const fieldName = event.fieldName;

    // Limpiar campos existentes
    if (fieldName === 'questionType') {
        this.modalFields = this.originalModalFields.filter(field => 
            field.name !== 'totalOptions' && 
            field.name !== 'totalWhiteAnswers' && 
            field.name !== 'totalQuestions'
        );

        let newModalFields = [];
        
        // Añadir campos según el tipo de pregunta seleccionada
        switch (selectedType) {
            case 1:
            case 2:
            case 4:
                newModalFields.push({
                    name: 'totalOptions',
                    type: 'select',
                    label: 'Total de opciones*',
                    required: true,
                    dynamic: false,
                    errorMessage: 'El total de opciones es obligatorio.',
                    value: null,
                    change: true,
                    options: this.totalOptionsAndAnswers.map(option => ({ value: option.id, label: option.label }))
                });
                break;
            case 3:
                newModalFields.push({
                    name: 'totalWhiteAnswers',
                    type: 'select',
                    label: 'Total de respuestas en blanco*',
                    required: true,
                    dynamic: false,
                    value: null,
                    errorMessage: 'Este campo es obligatorio.',
                    change: true,
                    options: this.totalOptionsAndAnswers.map(option => ({ value: option.id, label: option.label }))
                });
                break;
            case 5:
            case 6:
            case 7:
                newModalFields.push({
                    name: 'totalQuestions',
                    type: 'select',
                    label: 'Total de preguntas*',
                    required: true,
                    dynamic: false,
                    errorMessage: 'El total de preguntas es obligatorio.',
                    change: true,
                    options: this.totalOptionsAndAnswers.map(option => ({ value: option.id, label: option.label }))
                });
                newModalFields.push({
                    name: 'totalOptions',
                    type: 'select',
                    label: 'Total de opciones*',
                    required: true,
                    dynamic: false,
                    errorMessage: 'El total de opciones es obligatorio.',
                    change: true,
                    options: this.totalOptionsAndAnswers.map(option => ({ value: option.id, label: option.label }))
                });
                break;
            default:
                break;
        }

        this.modalFields = [...this.originalModalFields, ...newModalFields];
    } else if (fieldName === 'totalOptions') {
        // Limpiar campos de opciones existentes
        const existingOptions = this.modalFields.filter(field => field.relatedField === 'totalOptions');
        // Generar nuevos campos de opciones
        this.modalFields = this.modalFields.filter(field => field.relatedField !== 'totalOptions');
        for (let i = 1; i <= selectedType; i++) {
            this.modalFields.push({
                name: `option${i}`,
                type: 'text',
                label: `Opción ${i}`,
                dynamic: true,
                relatedField: 'totalOptions',
                required: true,
                errorMessage: `La opción ${i} es obligatoria.`
            });
            this.modalFields.push({
                name: `optionImage${i}`,
                type: 'file',
                label: `Imagen ${i}`,
                dynamic: true,
                relatedField: 'totalOptions',
                required: true,
                value: null,
                errorMessage: `La opción ${i} es obligatoria.`
            });
        }

        // Agregar el campo "Número de respuesta"
        this.modalFields.push({
            name: `answerNumber`,
            type: 'number',
            label: `Número de respuesta`,
            dynamic: true,
            relatedField: 'totalOptions',
            required: true,
            value: null,
            errorMessage: `El número de respuesta es obligatorio.`
        });
    } else if (fieldName === 'totalQuestions') {
        // Limpiar campos de preguntas existentes
        this.modalFields = this.modalFields.filter(field => field.relatedField !== 'totalQuestions');
        // Generar nuevos campos de preguntas
        for (let i = 1; i <= selectedType; i++) {
            this.modalFields.push({
                name: `question${i}`,
                type: 'text',
                label: `Pregunta ${i}`,
                dynamic: true,
                relatedField: 'totalQuestions',
                required: true,
                errorMessage: `La pregunta ${i} es obligatoria.`
            });
            this.modalFields.push({
                name: `answer${i}`,
                type: 'text',
                label: `Respuesta ${i}`,
                dynamic: true,
                relatedField: 'totalQuestions',
                required: true,
                errorMessage: `La respuesta ${i} es obligatoria.`
            });
        }
    } else if (fieldName === 'totalWhiteAnswers') {
        // Limpiar campos de respuestas en blanco existentes
        const existingWhiteAnswers = this.modalFields.filter(field => field.relatedField === 'totalWhiteAnswers');
        if (existingWhiteAnswers.length === 0) {
            for (let i = 1; i <= selectedType; i++) {
                this.modalFields.push({
                    name: `whiteAnswer${i}`,
                    type: 'text',
                    label: `Respuesta en blanco ${i}`,
                    dynamic: true,
                    relatedField: 'totalWhiteAnswers',
                    required: true,
                    errorMessage: `La respuesta en blanco ${i} es obligatoria.`
                });
            }
        }
    }
}



  // Método que captura la información al guardar en el modal
  onFormSubmit(data: any) {
    if (this.esMateria) {
      this.guardarMateria(data);
    } else {
      this.guardarPregunta(data)
    }
  }

  guardarMateria(data: any) {
    console.log(data)
    const request: any = {
      id: null,
      title: data.title,
      code: data.code,
    }
    this.examenesService.guardarMateria(request).subscribe(
      (result) => {
        this.toastrService.success("Se guardo la materia correctamente", "Exito")
        this.cerrarModal()
        this.obtenerPreguntas()
      },
      (error: any) => {
        this.toastrService.error("No se pudo guardar la materia", "Error")
      }
    );
  }

  guardarPregunta(data: any) {
    // Inicializa el array de opciones
    let optionsArray = [];
    console.log(data)
    // Verifica cuántas opciones se generaron dinámicamente (puede que esto venga del valor de 'totalOptions')
    const totalOptions = parseInt(data.totalOptions, 10); // Cambia esto por el campo correcto si es necesario
    
    // Recorre cada opción
    for (let i = 1; i <= totalOptions; i++) {
      // Crea el objeto 'optionRequest'
      let optionRequest = {
        optionValue: data[`option${i}`] || "", // El valor de la opción
        hasFile: data[`optionImage${i}`] ? 1 : 0, // Si hay archivo, 1; si no, 0
        fileName: data[`optionImage${i}`] ? data[`optionImage${i}`].name : "" 
        // Nombre del archivo si existe
      };
      // Agrega el objeto al array de opciones
      optionsArray.push(optionRequest);
    }
    
    // Construye el request con el arreglo de opciones
    const request = {
      subjectId: this.idMateriaGuardar,
      question: data.question,
      questionType: data.questionType,
      status: 1,
      topic: data.topic,
      totalOptions:data.totalOptions,
      difficultyLevel: data.difficulty,
      time: data.time,
      tips: data.tips || '',
      description: data.description || '',
      totalAnswers:optionsArray.length,
      totalCorrectAnswers:1,
      explanation:'',
      hint:'',
      marks:data.marks,
      questionFileIsUrl:0,
      correctAnswers:data.answerNumber,
      options: optionsArray // Aquí asignas el array de opciones generado
    };
     console.log(request)
    this.examenesService.guardarPregunta(request).subscribe(
      (result) => {
        this.toastrService.success("Se guardó la pregunta correctamente", "Éxito");
        this.cerrarModal();
        this.obtenerPreguntas();
      },
      (error: any) => {
        this.toastrService.error("No se pudo guardar la pregunta", "Error");
        console.error(error)
      }
    );
  }

  cerrarModal() {
    this.modalRef.hide()
  }

  filterPreguntas(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.toLowerCase();

    this.materias = this.originalMaterias.filter(mat =>
      mat.name.toLowerCase().includes(this.searchTerm)
    );
    this.currentPage = 1; // Resetear a la primera página después de filtrar
  }

  verPreguntas(subjectId: number) {
    const materia = this.materias.find(materia => materia.id === subjectId);
    const materiaName = materia ? materia.name : 'Materia no encontrada';
    console.log(materiaName, 'materia desde banco')
    this.router.navigate(['/ver-preguntas', subjectId, materiaName]);
  }

  changePageSize(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage;
  }
}
