<app-sidebar-menu></app-sidebar-menu>
<app-examenes-admin
  [columns]="columns"
  [data]="categories"
  title="Categorias de Examen"
  buttonText="Nueva Categoria"
  (onOpenModal)="abrirModal($event)"
  (onFilterChange)="filterCategories($event)">
</app-examenes-admin>

<ng-template #dynamicModal>
  <app-dynamic-modal
    [modalTitle]="modalTitle"
    [fields]="modalFields"
    [initialData]="initialData"
    (formSubmit)="onFormSubmit($event)"
    (closeModal)="cerrarModal()"
    (fileChange)="onFileSelected($event)">
  </app-dynamic-modal>
</ng-template>



