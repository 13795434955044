<div class="modal-header">
    <h5 class="modal-title">{{ modalTitle }}</h5>
    <button type="button" class="btn-close" (click)="onCloseModal()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
        <div *ngFor="let field of fields" class="mb-3">
            <label>{{ field.label }}</label>

            <!-- Input de texto -->
            <input *ngIf="field.type === 'text'" [placeholder]="field.placeholder ? field.placeholder : ''" type="text"
                class="form-control" [formControlName]="field.name">

            <!-- Input numerico -->
            <input *ngIf="field.type === 'number'" type="number" class="form-control" [formControlName]="field.name">

            <!-- Input de fecha -->
            <input *ngIf="field.type === 'date'" type="date" class="form-control" [formControlName]="field.name">

            <!-- Select -->
            <select *ngIf="field.type === 'select'" class="form-select" [formControlName]="field.name"
                (change)="field.change ? onSelectChange($event, field) : null">
                <option *ngFor="let option of field.options" [value]="option.value">{{ option.label }}</option>
            </select>

            <!-- Textarea -->
            <textarea *ngIf="field.type === 'textarea'" class="form-control" [formControlName]="field.name"></textarea>

            <!-- Editor de Texto -->
            <ckeditor *ngIf="field.type === 'editor'" [editor]="Editor" [formControlName]="field.name"></ckeditor>

            <div *ngIf="field.type === 'file'">
                <!-- Vista previa de la imagen si existe -->
                <div *ngIf="field.value && !changeImage" class="image-preview">
                    <img [src]="'assets/img/categorias-tabla/' + field.value" alt="Imagen previa" width="50" height="50">
                    <button type="button" class="btn btn-sm btn-secondary" (click)="toggleImageChange()">Cambiar Imagen</button>
                </div>

                <!-- Input para cargar nueva imagen -->
                <div *ngIf="changeImage || field.value === null" class="image-upload">
                    <input type="file" class="form-control" (change)="onFileChange($event)" [formControlName]="field.name">
                    <button *ngIf="field.value !== null" type="button" class="btn btn-sm btn-danger" (click)="restorePreviousImage()">Restaurar Imagen Anterior</button>
                </div>
            </div>

            <!-- Mostrar feedback de error, solo si el control existe -->
            <div *ngIf="form.get(field.name) && form.get(field.name).invalid && form.get(field.name).touched" class="invalid-feedback">
                {{ field.errorMessage }}
            </div>
        </div>

        <!-- Deshabilitar el botón de guardar solo si el formulario es inválido y está definido -->
        <button type="submit" class="btn btn-primary" [disabled]="form?.invalid">Guardar</button>
    </form>
</div>
