<app-sidebar-menu></app-sidebar-menu>
<app-examenes-admin
  [columns]="columns"
  [data]="temas"
  title="Temas"
  buttonText="Nuevo Tema"
  (onOpenModal)="abrirModal($event)"
  (onFilterChange)="filterTemas($event)"
  (onMenuToggle)="onMenuToggle($event)"> <!-- Suscribirse al evento -->
</app-examenes-admin>


<ng-template #dynamicModal>
    <app-dynamic-modal
      [modalTitle]="modalTitle"
      [fields]="modalFields"
      [initialData]="initialData"
      (formSubmit)="guardarTema($event)"
      (closeModal)="cerrarModal()"
      (selectChange)="obtenerTemasPorMateria($event)">
    </app-dynamic-modal>
  </ng-template>
