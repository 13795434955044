<div class="container" *ngIf="instrucciones !==null && instrucciones !== undefined">
  <!-- CONTENEDOR DE INSTRUCCIONES ANTES DE INICIAR EL EXAMEN -->
  <div class="pre-quiz" *ngIf="!mostrarExamen">
    <div class="header">
      <h5>INSTRUCCIONES</h5>
      <span>Lea las instrucciones con cuidado</span>
    </div>
    <div class="instructions-and-title">
      <div class="titles">
        <h5>Nombre del examen: {{ instrucciones?.quizTitle }}</h5>
        <h5>{{ instrucciones?.instructionTitle }}</h5>
      </div>
      <p>{{ instrucciones?.instructionContent }}</p>
    </div>
    <div class="examen-body">
      <div class="instructions-list-buttons">
        <div class="instructions-button"><button class="btn btn-sm btn-success"><i class="bx bx-check"></i></button>
          <span>Haz respondido la pregunta</span>
        </div>
        <div class="instructions-button"><button class="btn btn-sm btn-danger"><i class="bx bx-x"></i></button>
          <span>No has respondido la pregunta</span>
        </div>
        <div class="instructions-button"><button class="btn btn-sm btn-primary"><i class="bx bx-show"></i></button>
          <span>Haz respondido la pregunta, pero la has marcado para su revisión</span>
        </div>
        <div class="instructions-button"><button class="btn btn-sm btn-secondary"><i class="bx bx-hide"></i></button>
          <span>Aún no has visitado la pregunta</span>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="termins-check">
        <input type="checkbox" (change)="aceptarTerminos()">
        <label for="termins-check">He leído y entendido las instrucciones indicadas anteriormente
          para realizar el examen</label>
      </div>
      <div class="start-button">
        <button class="btn btn-success" [disabled]="!terminosAceptados" (click)="comenzarExamen()">Comenzar
          Examen</button>
      </div>
    </div>
  </div>

  <!-- CONTENEDOR DE EXAMEN -->
  <div class="quiz" *ngIf="mostrarExamen">
    <div class="body">
      <div class="header">
        <label (click)="irAPreguntaAnterior()"><i class="bx bx-arrow-from-right"></i>Anterior</label>
        <label (click)="irAPreguntaSiguiente()"><i class="bx bx-arrow-from-left"></i>Siguiente</label>
        <label><i class="bx bx-trash"></i>Limpiar</label>
      </div>
      <div class="question-container">
        <h5>{{instrucciones?.quizTitle}} pregunta {{currentQuestionIndex + 1}} de {{instrucciones?.questions.length}}
        </h5>
        <div class="question" *ngIf="instrucciones?.questions.length > 0">
          <span class="question-span"
            [innerHTML]="instrucciones.questions[currentQuestionIndex]?.questionText | decodeHtml"></span>
          <div class="answers">
            <div *ngFor="let option of instrucciones.questions[currentQuestionIndex]?.answers">
              <label class="option">
                <input class="radio-button" type="checkbox"
                  name="answer-{{instrucciones.questions[currentQuestionIndex]?.id}}" [value]="option.option_index"
                  (change)="seleccionarOpcion($event)">
                {{option.option_value.option_value}}
              </label>
            </div>
          </div>


        </div>
      </div>
      <div class="footer">
        <div class="primary-buttons">
          <button class="btn btn-sm btn-success" (click)="irAPreguntaAnterior()"
          [disabled]="currentQuestionIndex === 0">
            <i class="bx bx-arrow-from-right"></i>Anterior</button>
          <button class="btn btn-sm btn-success" (click)="irAPreguntaSiguiente()"
          [disabled]="currentQuestionIndex === instrucciones?.questions.length - 1">
            <i class="bx bx-arrow-from-left"></i>Siguiente</button>
        </div>
        <div class="secondary-buttons">
          <button class="btn btn-sm btn-secondary" (click)="marcarParaRevisarYAvanzar()">
            <i class="bx bx-radio-circle-marked"></i>Marcar para revisar y Siguiente</button>
          <button class="btn btn-sm btn-danger" (click)="limpiarRespuesta()">
            <i class="bx bx-trash"></i>Limpiar Respuesta</button>
          <button class="btn btn-sm btn-primary" (click)="terminarExamen()"><i
              class="bx bx-check-circle"></i>Finalizar</button>
        </div>
      </div>
    </div>
    <div class="right-panel">
      <div class="counter">
        <h4>Tiempo restante:</h4>
        <div class="time-display">
          <span class="time">{{ minutos }}:{{ segundos < 10 ? '0' + segundos : segundos }}</span>
        </div>
      </div>
      <div class="answers-panel">
        <div class="answer-number-box" style="cursor: pointer;"
          *ngFor="let pregunta of instrucciones.questions; let i = index" [ngClass]="{
           'bg-danger': estadoPreguntas[pregunta.id] === 'notAnswered',
           'bg-success': estadoPreguntas[pregunta.id] === 'answeredCorrectly',
           'bg-primary': estadoPreguntas[pregunta.id] === 'markedForReview',  
           'bg-secondary': estadoPreguntas[pregunta.id] === 'notVisited'
         }" (click)="irAPregunta(i)">
          <h3>{{ i + 1 }}</h3>
        </div>

      </div>
      <div class="status-boxes">
        <h4>Resumen</h4>
        <div class="first-controls">
          <div class="status-control">
            <div class="status-box bg-success">
              <p class="status-number">{{ cantidadRespondidas }}</p>
            </div>
            <label class="status-label">Respondidas</label>
          </div>
          <div class="status-control">
            <div class="status-box bg-danger">
              <p class="status-number">{{ cantidadNoRespondidas }}</p>
            </div>
            <label class="status-label">No Respondidas</label>
          </div>
        </div>
        <div class="last-controls">
          <div class="status-control">
            <div class="status-box bg-primary">
              <p class="status-number">{{ cantidadMarcadas }}</p>
            </div>
            <label class="status-label">Marcadas para Revisión</label>
          </div>
          <div class="status-control">
            <div class="status-box bg-secondary">
              <p class="status-number">{{ cantidadNoVisitadas }}</p>
            </div>
            <label class="status-label">No Visitadas</label>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>