<app-sidebar-menu></app-sidebar-menu>
<app-examenes-admin
  [columns]="columns"
  [data]="materias"
  title="Temas de la pregunta"
  buttonText="Agregar Materia"
  (onPrimaryAction)="verPreguntas($event)"
  (onFilterChange)="filterPreguntas($event)"
  (onOpenModal)="abrirModal($event)">
</app-examenes-admin>

<ng-template #dynamicModal>
    <app-dynamic-modal
      [modalTitle]="modalTitle"
      [fields]="modalFields"
      [initialData]="initialData"
      (closeModal)="cerrarModal()"
      (formSubmit)="onFormSubmit($event)"
      (selectChange)="handleQuestionTypeChange($event)">
    </app-dynamic-modal>
  </ng-template>

 
