<app-sidebar-menu></app-sidebar-menu>
<div class="container">
    <div class="profile-container">
        <div class="img-container">
            <img src="assets/img/user.png" alt="">
        </div>
        <div class="user-info-container">
            <h4>{{nombreUsuario}}</h4>
            <span>{{email}}</span>
        </div>
    </div>
    <div class="results-container">
        <div class="score-box">
            <span class="span-box">Puntaje</span>
            <h4 class="number-box">{{correctAnswersCount}}/{{result.totalMarks}}</h4>
        </div>
        <div class="percentage-box">
            <span class="span-box">Porcentaje</span>
            <h4 class="number-box">{{ result.percentage | number: '1.2-2' }}</h4>
          </div>
        <div class="result-box">
            <span class="span-box">Resultado</span>
            <h4 class="number-box">{{result.examStatus == 'fail'? 'No aprobado' : 'Aprobado'}}</h4>
        </div>
    </div>
    <div class="charts-container">
        <div class="chart">
          <canvas id="respuestasChart"></canvas>
        </div>
        <div class="chart">
          <canvas id="tiempoChart"></canvas>
        </div>
    </div>
    <!-- <div class="button-container">
        <button class="btn btn-primary" (click)="verDetalle(result.slug)">Ver resultados</button>
    </div> -->
    <div class="button-container">
        <button class="btn btn-primary" (click)="verDetalle(result.slug)">Ver estadisticas</button>
    </div>
</div>
