import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-dynamic-modal',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, CKEditorModule],
  templateUrl: './dynamic-modal.component.html',
  styleUrl: './dynamic-modal.component.scss',
  providers:[BsModalService]
})
export class DynamicModalComponent {
  @Input() initialData: any = null;
  @Input() fields: any[] = [];
  @Input() modalTitle: string;
  @Output() formSubmit = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();
  @Output() selectChange = new EventEmitter<any>();
  @Output() fileChange = new EventEmitter<File>();

  form: FormGroup;

  // TemplateRef del modal
  @ViewChild('dynamicModal') modalTemplate: TemplateRef<any>;

  changeImage = false;     // Controla si se muestra el input de cambiar imagen
  previousImage: string | null = null; // Guarda la imagen previa

  public Editor = ClassicEditor;
  dynamicFields: any[] = [];
  originalInitialData:any[] = [];
  private fieldTemp : any[] = []

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
    this.originalInitialData = [...this.initialData]
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialData && !changes.initialData.firstChange) {
      console.log('cambia data')
      // Si initialData cambia y no es la primera vez que el componente se inicializa
      this.buildForm(); // Reconstruir el formulario con los nuevos datos
    }
    if (changes.fields && !changes.fields.firstChange) {
        console.log('cambia data')
        this.fieldTemp = changes.fields.previousValue;
        // Si initialData cambia y no es la primera vez que el componente se inicializa
        this.reBuildForm(); // Reconstruir el formulario con los nuevos datos
      }
  }

  buildForm() {
    const controlsConfig = {};
    this.fields.forEach(field => {
      const value = this.initialData ? field.value : '';
      controlsConfig[field.name] = [value, field.required ? Validators.required : []];
      // Guarda la imagen previa
      if (field.type === 'file' && field.value) {
        this.previousImage = field.value;
      }
    });
    this.form = this.fb.group(controlsConfig);
  }

  reBuildForm() {
    const controlsConfig = {};
    this.fields.forEach(field => {
      const value = this.initialData ? field.value : '';
      const valueTemp = this.fieldTemp.filter(v => v.name === field.name);
      if(!valueTemp){
        controlsConfig[field.name] = [value, field.required ? Validators.required : []];
        // Guarda la imagen previa
        if (field.type === 'file' && field.value) {
          this.previousImage = field.value;
        }
      }else{
        const control = this.form.get(field.name);
        controlsConfig[field.name] = control;
      }
    });
    this.form = this.fb.group(controlsConfig);
  }

  submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.formSubmit.emit(this.form.value);
  }

  toggleImageChange() {
    this.changeImage = !this.changeImage;
  }

  restorePreviousImage() {
    this.changeImage = false;
    this.form.get('imagen').setValue(this.previousImage);
  }

  
onFileChange(event: any) {
  const file = event.target.files[0];
  if (file) {
    this.fileChange.emit(file);  // Emitir el archivo seleccionado al componente padre
  }
}

  onSelectChange(event: Event, field: any) {
    
    const selectedValue = (event.target as HTMLSelectElement).value;
    console.log(selectedValue, 'evento')
    this.selectChange.emit({ selectedValue, fieldName: field.name });
  }

  onCloseModal(){
    this.closeModal.emit();
  }
}
