<app-sidebar-menu></app-sidebar-menu>
<div class="container">
    <div class="form-questions">
      <div class="form-questions-title">
        <h3>Actualizar preguntas para {{examenName}}</h3>
      </div>
  
      <!-- Selector de Materias -->
      <div class="first-fields">
        <div class="field">
          <label>Asignaturas*</label>
          <select class="form-select" (change)="onSubjectChange($event.target.value)">
            <option [value]="null">Seleccionar</option>
            <option *ngFor="let mat of materias" [value]="mat.id">{{mat.name}}</option>
          </select>
        </div>
  
        <!-- Selector de Dificultad -->
        <div class="field">
          <label>Dificultad</label>
          <select class="form-select" (change)="onDifficultyChange($event.target.value)">
            <option [value]="null">Seleccionar</option>
            <option value="easy">Fácil</option>
            <option value="medium">Medio</option>
            <option value="hard">Difícil</option>
          </select>
        </div>
      </div>
  
      <!-- Selector de Tipo de Pregunta -->
      <div class="last-fields">
        <div class="field">
          <label>Tipo de pregunta</label>
          <select class="form-select" (change)="onQuestionTypeChange($event.target.value)">
            <option [value]="null">Seleccionar</option>
            <option value="radio">Respuesta individual</option>
            <option value="checkbox">Respuesta múltiple</option>
            <option value="blanks">Llenar espacios en blanco</option>
            <option value="match">Une el siguiente</option>
            <option value="para">Párrafo</option>
            <option value="video">Video</option>
            <option value="audio">Audio</option>
          </select>
        </div>
  
        <!-- Campo de búsqueda -->
        <div class="field">
          <label>Término de búsqueda</label>
          <input class="form-control" type="text" placeholder="Insertar término de búsqueda"
          (keyup)="filtrarPreguntas($event.target.value)" >
        </div>
      </div>

      <div class="questions-table" *ngIf="seHizoPeticion">
        <table class="table table-sm table-bordered" id="basic-datatable">
            <thead>
                <tr>
                    <th>Materia</th>
                    <th>Pregunta</th>
                    <th>Nivel</th>
                    <th>Tipo</th>
                    <th>Marcas</th>
                    <th>Accion</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let preg of filteredPreguntasPrevias">
                    <td>{{preg.subjectName}}</td>
                    <td>{{preg.questionText}}</td>
                    <td>{{preg.difficultyLevel}}</td>
                    <td>{{preg.questionType}}</td>
                    <td>{{preg.marks}}</td>
                    <td>
                        <button class="btn btn-sm btn-primary" (click)="agregarPregunta(preg)">Agregar</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <span *ngIf="preguntasPrevias.length === 0">No hay preguntas previas</span>
      </div>
    </div>
  
    <!-- Panel derecho -->
    <div class="right-panel">
      <div class="right-panel-title">
        <h3>Preguntas Guardadas</h3>
      </div>
      <div class="right-panel-label">
        <label>Preguntas Guardadas({{preguntasGuardadas.length}}) Total de puntos: {{cantidadMarcas}}</label>
        <label (click)="eliminarTodasLasPreguntas()"
        class="text-danger" style="text-decoration: underline; cursor: pointer;" >Eliminar todas</label>
      </div>
      <div class="right-panel-table" *ngIf="preguntasGuardadas.length > 0">
        <table class="table table-sm table-bordered" id="basic-panel-datatable">
            <thead >
                <tr>
                    <th>Materia</th>
                    <th>Pregunta</th>
                    <th>Marcas</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of preguntasGuardadas">
                    <td>{{item.subjectName}}</td>
                    <td>{{item.questionText}}</td>
                    <td>{{item.marks}}</td>
                    <td><button class="btn btn-sm btn-danger" (click)="quitarPregunta(item)">
                        <i class="bx bx-trash"></i>
                    </button></td>
                </tr>
            </tbody>
        </table>
      </div>
      <div class="right-panel-button">
        <button class="btn btn-success" (click)="guardarListaPreguntas()">Actualizar</button>
      </div>
    </div>
  </div>
  
