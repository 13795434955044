<div class="cart-area ptb-100">
  <div class="container">
    <div class="title-container">
      <h3>{{title}}</h3>
    </div>
    <div class="header">
      <div class="button-container" *ngIf="buttonText !== ''">
        <button class="btn btn-primary" (click)="onEdit(null)">{{buttonText}}</button>
      </div>
      <div class="search-container">
        <input type="text" placeholder="Buscar aqui..." class="input-search" (keyup)="filterChange($event)">
      </div>
      <div class="registers-container">
        <span> Mostrar </span>
        <select id="pageSizeSelect" (change)="changePageSize($event)">
          <option value="5">5</option>
          <option value="10" selected>10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <span> Registros </span>
      </div>
    </div>
    <div class="table-container">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th *ngFor="let column of columns">{{ column.title }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data | paginate: currentPage : pageSize">
            <td *ngFor="let column of columns">
              <ng-container [ngSwitch]="column.type">
                <!-- Para columnas de tipo 'image' -->
                <ng-container *ngSwitchCase="'image'">
                  <img *ngIf="item[column.key]" [src]="item[column.key]" alt="Image" style="width: 50px; height: auto;">
                </ng-container>                

                <!-- Para columnas de tipo 'actions' -->
                <ng-container *ngSwitchCase="'actions'">
                  <div class="dropdown">
                    <button class="btn btn-link dropdown-toggle" type="button" (click)="toggleMenu(item.id, $event)" aria-expanded="isMenuOpen[item.id]">
                      &#x22EE; <!-- Tres puntos verticales -->
                    </button>
                    <ul class="dropdown-menu" [ngClass]="{'show': isMenuOpen[item.id]}" (mouseleave)="closeMenu(item.id)">
                      <li *ngIf="column.buttonOne !== null">
                        <button class="dropdown-item" (click)="primaryAction(item.id)">{{column.buttonOne}}</button>
                      </li>
                      <li *ngIf="column.buttonTwo !== null">
                        <button class="dropdown-item" (click)="onEdit(item)">{{column.buttonTwo}}</button>
                      </li>
                    </ul>
                  </div>
                </ng-container>
                <!-- Para columnas normales -->
                <ng-container *ngSwitchDefault>
                  {{ item[column.key] }}
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
          <!-- Paginación -->
    <app-paginacion *ngIf="data.length >= pageSize" [paginaActual]="currentPage"
      [elementosPorPagina]="pageSize" [totalElemetos]="data.length" (cambioPagina)="changePage($event)">
    </app-paginacion>
    <span *ngIf="data.length === 0" style="font-weight: 500;">*Actualmente no hay registros</span>
    </div>
  </div>
</div>