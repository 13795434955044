import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";
import { ExamenesService } from 'src/app/service/examenes.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DynamicModalComponent } from "../../../../common/dynamic-modal/dynamic-modal.component";
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-examenes-table',
  standalone: true,
  imports: [ExamenesAdminComponent, SidebarMenuComponent, DynamicModalComponent],
  templateUrl: './examenes-table.component.html',
  styleUrl: './examenes-table.component.scss',
  providers:[BsModalService, ToastrService, DatePipe]
})
export class ExamenesTableComponent implements OnInit{
  // Definir columnas dinámicamente
  columns = [
    { key: 'title', title: 'Titulo' },
    { key: 'nameCategory', title: 'Categoria' },
    { key: 'curso', title: 'Curso' },
    { key: 'totalMarks', title: 'Total de Marcas' },
    { key: 'actions', title: 'Acciones', type: 'actions', buttonOne: 'Actualizar Preguntas', buttonTwo: 'Editar' }
  ];

  examenes:any[] = []
  originalExamenes:any[] = []
  searchTerm:string = ''
  pageSize: number = 10
  currentPage: number = 1
  idUsuario:any
  categories:any[] = []
  instrucciones:any [] = []

  //MODAL
  modalFields: any[] = [];
  originalModalFields: any[] = [];
  initialData: any = null;
  modalTitle = '';
  @ViewChild('dynamicModal') dynamicModalTemplate: TemplateRef<any>;
  idExamenGuardar:number
  totalQuestionsGuardar:number
  private modalRef: BsModalRef
  currentFieldValues: { fieldName: string, value: any }[] = [];
    //OPCIONES SELECT MODAL
  isPaidOptions = [{
    id: 0,
    label: 'Gratis'
  },
  {
    id: 1,
    label: 'Pagado'
  }]

  constructor(private examenesService: ExamenesService, private modalService: BsModalService,
    private toastrService: ToastrService,  private datePipe: DatePipe, private router: Router){
    this.idUsuario = localStorage.getItem('idUsuario');
  }

  ngOnInit(): void {
    this.obtenerExamenesAdmin()
    this.obtenerCategoriasSelect()
    this.obtenerInstruccionesGenerales()
  }

  obtenerExamenesAdmin() {
    this.examenesService.obtenerExamenesAdmin().subscribe(
      (response: any[] = []) => {
        console.log(response, 'examenes')
        this.examenes = response
        this.originalExamenes = [...response]
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  obtenerCategoriasSelect() {
    this.examenesService.obtenerTablaCategoriasAdmin().subscribe(
      (response: any[] = []) => {
        this.categories = response
        console.log(response, 'cats')
      },
      (error: any) => {
        console.error('Error obteniendo categorías', error);
      }
    );
  }

  obtenerInstruccionesGenerales() {
    this.examenesService.obtenerInstruccionesGenerales().subscribe(
      (response: any[] = []) => {
        console.log(response, 'inst')
        this.instrucciones = response
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  guardarExamen(datos: any) {
    console.log(datos, 'datos')
    // Formatear las fechas a 'yyyy-MM-ddTHH:mm:ss'
    const fechaInicioFormateada = this.datePipe.transform(datos.fechaInicio, 'yyyy-MM-ddTHH:mm:ss');
    const fechaFinFormateada = this.datePipe.transform(datos.fechaFin, 'yyyy-MM-ddTHH:mm:ss');

     // Validar que fechaFin no sea menor que fechaInicio
  if (fechaFinFormateada && fechaInicioFormateada && new Date(fechaFinFormateada) < new Date(fechaInicioFormateada)) {
    this.toastrService.error("La fecha fin no puede ser menor que la fecha inicio", "Error de validación");
    return;
  }

  
    const request: any = {
      id: this.idExamenGuardar ? this.idExamenGuardar : null,
      title: datos.titulo,
      description: datos.descripcion,
      startDate: fechaInicioFormateada, // Utilizar la fecha formateada
      endDate: fechaFinFormateada,      // Utilizar la fecha formateada
      categoryId: datos.categoria,
      duration: datos.duracion,
      totalQuestions: this.totalQuestionsGuardar,
      instructionsPageId: datos.instructionsPage,
      totalMarks: datos.totalPuntos,
      isPaid: datos.pagado,
      cost: datos.cost,
      validity: datos.validity,
      negativeMark: datos.marcaNegativa,
      havingNegativeMark: datos.marcaNegativa != 0 ? 1 : 0,
      passPercentage: datos.aprobacion,
      tags: "",
      recordUpdatedBy: parseInt(this.idUsuario)
    };
    this.examenesService.guardarExamen(request).subscribe(
      (result: any) => {
        console.log(result);
        this.toastrService.success("Examen guardado correctamente!", "Exito");
        this.obtenerExamenesAdmin();
        this.cerrarModal();
      },
      (error: any) => {
        console.error('Error guardando examen', error);
        this.toastrService.error("No se pudo guardar el examen", "Error");
      }
    );
  }

  filterExamenes(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.toLowerCase();

    this.examenes = this.originalExamenes.filter(exam =>
      exam.title.toLowerCase().includes(this.searchTerm)
    );
    this.currentPage = 1;
  }

  formatDate(dateString: string): string {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Asegura que el mes tenga dos dígitos
    const day = ('0' + date.getDate()).slice(-2); // Asegura que el día tenga dos dígitos
    return `${year}-${month}-${day}`;
  }
  

  abrirModal(datos: any) {
    this.idExamenGuardar = datos ? datos.id : null
    this.totalQuestionsGuardar = datos ? datos.totalQuestions : 0
    this.modalTitle = datos ? 'Editar Examen' : 'Agregar Examen';
    
    // Generar los campos básicos del modal
    this.modalFields = [
      {
        name: 'titulo', type: 'text', label: 'Titulo*', value:datos? datos.title : null,
        required: true, errorMessage: 'El titulo es obligatorio.',
      },
      {
        name: 'categoria', type: 'select', label: 'Categoria*', value:datos? datos.category.id : null,
        options: this.categories.map(option => ({ value: option.id, label: option.category })),
        required: true, errorMessage: 'La categoria es obligatoria.', 
      },
      {
        name: 'duracion', type: 'text', label: 'Duracion*', value:datos? datos.duration : null,
        required: true, errorMessage: 'La duracion es obligatoria.', placeholder:'Ingresa valor en minutos'
      },
      {
        name: 'totalPuntos', type: 'text', label: 'Total de Puntos*', value:datos? datos.totalMarks : null,
        required: true, errorMessage: 'El total de puntos es obligatorio.', 
      },
      {
        name: 'aprobacion', type: 'text', label: 'Porcentaje de Aprobacion*',value:datos? datos.passPercentage : null,
        required: true, errorMessage: 'La aprobacion es obligatoria.', 
      },
      {
        name: 'marcaNegativa', type: 'text', label: 'Marca negativa*', value:datos? datos.negativeMark : null,
        required: true, errorMessage: 'La marca negativa es obligatoria.', 
      },
      {
        name: 'instructionsPage', type: 'select', label: 'Instructions page*', 
        value:datos? datos.instructionsPageId : null,
        options: this.instrucciones.map(option => ({ value: option.id, label: option.title })),
        required: true, errorMessage: 'La pagina de instrucciones es obligatoria.', 
      },
      {
        name: 'fechaInicio', type: 'date', label: 'Fecha de Inicio', value:datos ? this.formatDate(datos.startDate) : null,
        required: false, errorMessage: 'El contenido es obligatorio.', 
      },
      {
        name: 'fechaFin', type: 'date', label: 'Fecha de Fin', value:datos ? this.formatDate(datos.endDate) : null,
        required: false, errorMessage: 'El contenido es obligatorio.', 
      },
      {
        name: 'pagado', type: 'select', label: 'Esta pagado*', value:datos? datos.isPaid : null,
        options: this.isPaidOptions.map(option => ({ value: option.id, label: option.label })),
        required: true, errorMessage: 'El campo es obligatorio.', change:true
      },
      {
        name: 'descripcion', type: 'textarea', label: 'Descripcion*', value:datos? datos.description : null,
        required: true, errorMessage: 'La descripcion es obligatoria.', 
      }
    ];
    
    // Verificar si el examen es pagado y agregar los campos adicionales
    if (datos && datos.isPaid === 1) {
      // Campos adicionales para exámenes pagados
      const paidFields = [
        {
          name: 'validity',
          type: 'number',
          label: 'Validez*',
          value: datos.validity ? datos.validity : null,
          required: true,
          dynamic: false,
          errorMessage: 'El campo es obligatorio.'
        },
        {
          name: 'cost',
          type: 'number',
          label: 'Costo*',
          required: true,
          dynamic: false,
          value: datos.cost ? datos.cost : null,
          errorMessage: 'Este campo es obligatorio.'
        }
      ];
  
      // Encuentra el índice del campo 'descripcion'
      const descripcionIndex = this.modalFields.findIndex(field => field.name === 'descripcion');
  
      if (descripcionIndex !== -1) {
        // Inserta los nuevos campos justo antes del campo 'descripcion'
        this.modalFields = [
          ...this.modalFields.slice(0, descripcionIndex), // Campos anteriores a 'descripcion'
          ...paidFields,                                  // Nuevos campos
          ...this.modalFields.slice(descripcionIndex)      // Campos desde 'descripcion' en adelante
        ];
      } else {
        // Si no se encuentra el campo 'descripcion', simplemente concatena los nuevos campos al final
        this.modalFields = [...this.modalFields, ...paidFields];
      }
    }
  
    // Guardar la estructura original de campos
    this.originalModalFields = [...this.modalFields];
    this.initialData = datos ? this.modalFields : null;
  
    this.modalRef = this.modalService.show(this.dynamicModalTemplate,  { class: 'modal-lg', backdrop: 'static' });
  }

  // Método para capturar los valores actuales de los campos del modal
  captureCurrentFieldValues() {
    this.currentFieldValues = this.modalFields.map(field => ({
      fieldName: field.name,
      value: field.value
    }));
  }

  // Método para restaurar los valores en los campos regenerados
  restoreFieldValues() {
    this.modalFields.forEach(field => {
      const matchingField = this.currentFieldValues.find(f => f.fieldName === field.name);
      if (matchingField) {
        field.value = matchingField.value;
      }
    });
  }

  handleQuestionTypeChange(event: any) {
    const selectedType = parseInt(event.selectedValue, 10); // Asegúrate de convertir el valor a número
    const fieldName = event.fieldName;

    // Captura los valores actuales antes de regenerar los campos
    this.captureCurrentFieldValues();

    let newModalFields = [];

    if (fieldName === 'pagado') {
      switch (selectedType) {
        case 1:
          newModalFields.push({
            name: 'validity',
            type: 'number',
            label: 'Validez*',
            value: null,
            required: true,
            dynamic: false,
            errorMessage: 'El campo es obligatorio.'
          });
          newModalFields.push({
            name: 'cost',
            type: 'number',
            label: 'Costo*',
            required: true,
            dynamic: false,
            value:null,
            errorMessage: 'Este campo es obligatorio.'
          });          
          break;
        case 0:
          // Eliminar cualquier campo adicional si se selecciona Gratis
          newModalFields = [];
          break;
        default:
          break;
      }

      // Encuentra el índice del campo 'descripcion'
      const descripcionIndex = this.originalModalFields.findIndex(field => field.name === 'descripcion');

      if (descripcionIndex !== -1) {
        // Inserta los nuevos campos justo antes del campo 'descripcion'
        this.modalFields = [
          ...this.originalModalFields.slice(0, descripcionIndex), // Campos anteriores a 'descripcion'
          ...newModalFields,                                      // Nuevos campos
          ...this.originalModalFields.slice(descripcionIndex)      // Campos desde 'descripcion' en adelante
        ];
      } else {
        // Si no se encuentra el campo 'descripcion', simplemente concatena los nuevos campos al final
        this.modalFields = [...this.originalModalFields, ...newModalFields];
      }

      // Restaurar los valores anteriores a los campos regenerados
      this.restoreFieldValues();
    }
  }

  irAActualizarPreguntas(quizId: number) {
    const examen = this.examenes.find(exam => exam.id === quizId);
    const examenName = examen ? examen.title : 'Examen no encontrado';
    console.log(examenName, 'materia desde banco')
    this.router.navigate(['/actualizar-preguntas', quizId, examenName]);
  }

  cerrarModal() {
    this.modalRef.hide()
  }
}
