import { Component, HostListener, OnDestroy } from '@angular/core';
import { ThemeCustomizerService } from '../theme-customizer/theme-customizer.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { menuItemsAdmin, menuItemsAlumno, menuItemsInstructor } from './menu-items';
import { ThemeCustomizerComponent } from "../theme-customizer/theme-customizer.component";

@Component({
    selector: 'app-sidebar-menu',
    standalone: true,
    templateUrl: './sidebar-menu.component.html',
    styleUrls: ['./sidebar-menu.component.scss'],
    imports: [CommonModule, RouterModule, ThemeCustomizerComponent]
})
export class SidebarMenuComponent implements OnDestroy {
  isOpen: boolean = false;
  isDarkTheme: boolean = false;
  currentUserRole: string;
  menuItemsAlumno: any[] = menuItemsAlumno;
  menuItemsInstructor: any[] = menuItemsInstructor;
  menuItemsAdmin: any[] = menuItemsAdmin;
  isMobile: boolean;

  constructor(private themeService: ThemeCustomizerService) {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isDarkTheme = isToggled;
    });
    this.currentUserRole = localStorage.getItem("rolUsuario");
    this.isMobile = window.innerWidth < 576;
    window.onresize = () => {
      this.isMobile = window.innerWidth < 576;
      ;
    };
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Remove event listener when the component is destroyed
    document.removeEventListener('click', this.documentClickListener);
  }

  getRouterLink(item: any): string {
    if(this.currentUserRole === "student"){
        switch (item.title) {
          case 'Inicio':
            return '/';
          case 'Cursos':
            return '/cursos';
          case 'Biblioteca':
            return '/biblioteca';
          case 'Diplomas':
            return '/diplomas';
          case 'Usuarios':
            return '/usuarios';
          case 'Evaluaciones':
            return '/evaluaciones';
          case 'Conoce ofertas de empleo':
            return 'https://empleos.tecgurus.net';
          default:
            return '/';
      }
    }else if(this.currentUserRole === "admin"){
      switch (item.title) {
        case 'Inicio':
          return '/';
        case 'Cursos':
          return '/cursos';
        case 'Biblioteca':
          return '/biblioteca';
        case 'Diplomas':
          return '/diplomas';
        case 'Usuarios':
          return '/usuarios';
        case 'Evaluaciones':
          return '/evaluaciones';
        case 'Conoce ofertas de empleo':
          return 'https://empleos.tecgurus.net';
        default:
          return '/';
      }
    }else{
      switch (item.title) {
        case 'Inicio':
          return '/';
        case 'Cursos':
          return '/cursos';
        case 'Biblioteca':
          return '/biblioteca';
        case 'Diplomas':
          return '/diplomas';
        case 'Usuarios':
          return '/usuarios';
        case 'Evaluaciones':
          return '/evaluaciones';
          case 'Examenes':
          return '/examenes';
        case 'Conoce ofertas de empleo':
          return 'https://empleos.tecgurus.net';
        default:
          return '/';
      }
    }
  }
  

  isInternalLink(item: string): boolean {
    return !item.startsWith('http');
  }

  getMenuItems(): any[] {
    switch (this.currentUserRole) {
      case 'student':
        return this.menuItemsAlumno;
      case 'Instructor':
        return this.menuItemsInstructor;
      case 'admin':
        return this.menuItemsAdmin;
      default:
        return [];
    }
  }

  handleClick(item: any, event: Event): void {
    if (item.subItems) {
      item.isOpen = !item.isOpen;
      event.preventDefault();
    }
  }

  openMenu() {
    this.isOpen = true;
    if (this.isMobile) {
      setTimeout(() => document.addEventListener('click', this.documentClickListener), 0);
    }
  }

  closeMenu() {
    this.isOpen = false;
    if (this.isMobile) {
      document.removeEventListener('click', this.documentClickListener);
    }
  }

  @HostListener('click', ['$event'])
  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  private documentClickListener = (event: MouseEvent) => {
    const sidebar = document.querySelector('.sidebar');
    if (sidebar && !sidebar.contains(event.target as Node)) {
      this.closeMenu();
    }
  };
}



