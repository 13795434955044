import { Component } from '@angular/core';
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { ActivatedRoute } from '@angular/router';
import { ExamenesService } from 'src/app/service/examenes.service';
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";

@Component({
  selector: 'app-ver-preguntas',
  standalone: true,
  imports: [ExamenesAdminComponent, SidebarMenuComponent],
  templateUrl: './ver-preguntas.component.html',
  styleUrl: './ver-preguntas.component.scss'
})
export class VerPreguntasComponent {
  columns = [
    { key: 'subject', title: 'Materia' },
    { key: 'topicName', title: 'Tema' },
    { key: 'questionType', title: 'Tipo' },
    { key: 'questionText', title: 'Pregunta' },
    { key: 'marks', title: 'Puntuaciones' },
    { key: 'difficulty', title: 'Dificultad' },
    // { key: 'actions', title: 'Acciones', type: 'actions', buttonOne:'Editar', buttonTwo:'Eliminar' }
  ];

  pageSize:number = 10
  currentPage:number = 1
  subjectId: number;
  materiaName:string;
  preguntas: any[] = [];
  title:string = ''

  constructor(private route: ActivatedRoute, private examenesService: ExamenesService) {}

  ngOnInit(): void {
    this.subjectId = +this.route.snapshot.paramMap.get('subjectId')!;
    this.materiaName = this.route.snapshot.paramMap.get('name')!;
    console.log(this.materiaName, 'materia en ver preg')
    this.obtenerPreguntas();
  }

  obtenerPreguntas() {
    this.examenesService.verPreguntasPorMateria(this.subjectId).subscribe(
      (response: any[] = []) => {
        this.preguntas = response;
        console.log(this.preguntas, 'preguntas obtenidas');
        this.title = this.materiaName + ' Preguntas'
      },
      (error: any) => {
        console.error('Error obteniendo preguntas', error);
      }
    );
  }
}

