import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExamenesService } from 'src/app/service/examenes.service';
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DynamicModalComponent } from "../../../../common/dynamic-modal/dynamic-modal.component";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-temas',
  standalone: true,
  imports: [SidebarMenuComponent, ExamenesAdminComponent, DynamicModalComponent],
  templateUrl: './temas.component.html',
  styleUrl: './temas.component.scss',
  providers:[BsModalService, ToastrService]
})
export class TemasComponent implements OnInit{
  columns = [
    { key: 'id', title: 'Id' },
    { key: 'name', title: 'Materia' },
    { key: 'subjectCode', title: 'Codigo' },
    { key: 'actions', title: 'Acciones', type: 'actions', buttonOne: null, buttonTwo: 'Editar' }
  ];

  temas: any[] = []
  originalTemas: any[] = []
  currentPage:number = 1
  searchTerm:string = ''
  
  //MODAL
  modalFields: any[] = [];
  initialData: any = null;
  modalTitle = '';
  @ViewChild('dynamicModal') dynamicModalTemplate: TemplateRef<any>;
  private modalRef: BsModalRef
  idMateriaGuardar:number
  constructor(private examenesService: ExamenesService, private modalService: BsModalService,
    private toastrService: ToastrService){

  }

  ngOnInit(): void {
  this.obtenerPreguntas()
  }

  obtenerPreguntas() {
    this.examenesService.obtenerPreguntasTabla().subscribe(
      (response: any[] = []) => {
        console.log(response, 'materias')
        this.temas = response
        this.originalTemas = [...response];
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  guardarMateria(data:any) {
    const request:any ={
      id: this.idMateriaGuardar,
      title: data.title,
      code: data.code,
    }
    this.examenesService.guardarMateria(request).subscribe(
      (result) => {
        this.toastrService.success("Se guardo la materia correctamente", "Exito")
        this.cerrarModal()
        this.obtenerPreguntas()
      },
      (error: any) => {
        this.toastrService.error("No se pudo guardar la materia", "Error")      }
    );
  }

  filterTemas(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.toLowerCase();

    this.temas = this.originalTemas.filter(tem =>
      tem.name.toLowerCase().includes(this.searchTerm)
    );
    this.currentPage = 1; // Resetear a la primera página después de filtrar
  }

  abrirModal(datos: any) {
    this.idMateriaGuardar = datos ? datos.id : null
    this.modalTitle = datos ? 'Editar Materia' : 'Nueva Materia';
    this.modalFields = [
      {
        name: 'title', type: 'text', label: 'Titulo de Materia',
        required: true, errorMessage: 'El titulo es obligatorio.', value: datos ? datos.name : null
      },
      {
        name: 'code', type: 'textarea', label: 'Codigo de Materia',
        required: true, errorMessage: 'El codigo es obligatorio.', value: datos ? datos.subjectCode : null
      }
    ];
    this.initialData = datos ? this.modalFields : null;
    this.modalRef = this.modalService.show(this.dynamicModalTemplate);
  }

  cerrarModal() {
    this.modalRef.hide()
  }
}
