import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamenesService {

  urlApi:string;

  constructor(private http: HttpClient) {
    this.urlApi = environment.baseApi;
  }

  obtenerCategorias(idUser: any) {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}examenes-alumno/${idUser}/settings`, { headers });
  }

  obtenerExamenesAdmin() {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}quizzes/all-admin`, { headers });
  }

  obtenerTablaCategoriasAdmin() {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}quiz-categories/admin`, { headers });
  }

  obtenerPreguntasTabla() {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}subjects/list`, { headers });
  }

  obtenerTemas() {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}subjects/topics-list`, { headers });
  }

  
  obtenerTemasPorMateria(subjectId:any) {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.get(`${this.urlApi}topics/${subjectId}`, { headers });
  }

  obtenerImagen(filename: string): Observable<Blob> {
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    const url = `${environment.baseApi}quiz-categories/uploads/categorias/${filename}`;
    return this.http.get(url, { headers, responseType: 'blob' });
}

  verPreguntasPorMateria(subjectId:number) {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}questions/by-subject/${subjectId}`, { headers });
  }

  obtenerExamenes(slug: string) {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}quiz-categories/${slug}`, { headers });
  }

  obtenerEstadisticasPorExamen(userId: any) {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}quizzes/by-exam/${userId}`, { headers });
  }

  obtenerEstadisticasHistoria(userId: any) {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}quizzes/history/${userId}`, { headers });
  }

  verDetalle(resultSlug:string,userId: any) {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}quizzes/${resultSlug}/user/${userId}/detail`, { headers });
  }

  generarCertificado(resultSlug: any) {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}certificates/generate/${resultSlug}` , { headers, responseType:'blob' });
  }
  
  obtenerInstruccionesYPreguntas(slug: string) {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}quizzes/start/${slug}`, { headers });
  }

  obtenerInstruccionesGenerales() {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}quizzes/instructions`, { headers });
  }

  cambiarMateria(subjectId:number, difficulty:string, questionType:string) {
    let params = new HttpParams();     
    if (subjectId !== null) {     
        params = params.append('subjectId', subjectId.toString());
      }     
      if (difficulty !== null) {     
        params = params.append('difficulty', difficulty.toString());
      } 
      if (questionType !== null) {     
        params = params.append('questionType', questionType.toString());
    } 
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}quizzes/change-subject`, {params, headers });
  }

  obtenerPreguntasGuardadas(quizId:number) {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.get(`${this.urlApi}quizzes/get-saved-questions/${quizId}`, { headers });
  }

  guardarListaPreguntas(quizId:number, request:any): Observable<any> {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.post(this.urlApi+`quizzes/questions-update/${quizId}`, request,{headers});
  }


  finalizarExamen(slug: string, request:any, idUsuario:any): Observable<any> {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.post(this.urlApi+`quizzes/finish/${slug}/${idUsuario}`, request,{headers});
  }

  guardarCategoria(request:any): Observable<any> {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.post(this.urlApi+`quiz-categories/save`, request,{headers});
  }

  guardarMateria(request:any): Observable<any> {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.post(this.urlApi+`subjects/add-or-update`, request,{headers});
  }

  guardarPregunta(request:any): Observable<any> {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.post(this.urlApi+`questions/add-or-update`, request,{headers});
  }

  guardarInstrucciones(request:any): Observable<any> {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.post(this.urlApi+`quizzes/add-or-update-instructions`, request,{headers});
  }

  guardarTema(request:any): Observable<any> {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.post(this.urlApi+`questions/add-or-update-topic`, request,{headers});
  }

  guardarExamen(request:any): Observable<any> {
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
    });
    return this.http.post(this.urlApi+`quizzes/add-or-update-quiz`, request,{headers,
       observe: 'response', responseType: 'text' });
  }

  subirImagen(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
  
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
    });  
    return this.http.post(this.urlApi + 'quiz-categories/uploads/categorias', formData, { headers });
  }  
}
