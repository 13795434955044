import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InicioService } from 'src/app/service/inicio.service';
import { SidebarMenuComponent } from "../../../common/sidebar-menu/sidebar-menu.component";
import { PaginacionComponent } from "../../../common/paginacion/paginacion.component";
import { PaginatePipe } from "../../../../pipes/paginate.pipe";
import { FechasService } from 'src/app/service/util/fechas.service';
import { FilterService } from 'src/app/service/util/filter.service';

@Component({
  selector: 'app-examenes-instructor',
  standalone: true,
  imports: [CommonModule, SidebarMenuComponent, PaginacionComponent, PaginatePipe],
  templateUrl: './examenes-instructor.component.html',
  styleUrls: ['./examenes-instructor.component.scss']
})
export class ExamenesInstructorComponent implements OnInit {
  examenes: any
  filteredExamenes: any[] = [];  // Array filtrado
  currentPage: number = 1;
  pageSize: number = 10;
  rolUsuario: string;
  searchTerm: string = ''; 
  isMobile:boolean

  constructor(
    private inicioService: InicioService,
    public dateService: FechasService,
    private filterService: FilterService
  ) {
    this.rolUsuario = localStorage.getItem('rolUsuario');
    this.isMobile = window.innerWidth < 576;
    window.onresize = () => {
      this.isMobile = window.innerWidth < 576;
      console.log(this.isMobile)
    };
  }

  ngOnInit(): void {
    this.obtenerTablaInst();
  }

  obtenerTablaInst() {
    this.inicioService.obtenerTablaInst(localStorage.getItem("idInstructor")).subscribe(datosInicio => {
      console.log(datosInicio, 'api nueva');
      this.examenes = datosInicio;
      this.filteredExamenes = [...this.examenes];
    }, error => {
      console.error(error);
    });
  }

  roundNumber(value: string): number {
    return Math.round(Number(value));
  }

  changePageSize(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
  }

  filterExamenes(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.toLowerCase();

    this.filteredExamenes = this.examenes.filter(examen =>
      examen.nombreCurso.toLowerCase().includes(this.searchTerm)
    );

    this.currentPage = 1;
  }

  toggleExpand(examen: any) {
    examen.isExpanded = !examen.isExpanded;
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage;
    console.log('page size', this.pageSize);
    console.log(this.currentPage, 'current page');
  }
}


