
<div class="cart-area ptb-100">
  <div class="container">
    <div class="myDashboard-content" *ngIf="rolUsuario === 'Instructor'">
      <div class="header">
        <div class="title-container">
          <h3>Mis Evaluaciones</h3>
        </div>
        <div class="search-container">
          <input type="text" placeholder="Buscar aqui..." class="input-search" (keyup)="filterExamenes($event)">
        </div>
        <div class="registers-containers">
          <span>Mostrar</span>
          <select name="" id="" (change)="changePageSize($event)">
            <option value="5">5</option>
            <option value="10" selected>10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <span>Registros</span>
        </div>
      </div>
      <div class="recent-orders-table" *ngIf="!isMobile">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Nombre del Curso</th>
              <th>Fecha Inicio</th>
              <th>Fecha Fin</th>
              <th>Total Encuesta</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of filteredExamenes | paginate: currentPage : pageSize">
              <td>{{item.nombreCurso}}</td>
              <td>{{dateService.formatearSoloFecha(item.fechaInicio)}}</td>
              <td>{{dateService.formatearSoloFecha(item.fechaFin)}}</td>
              <td>{{roundNumber(item.encuesta)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
       <!-- Estructura para dispositivos móviles -->
       <div *ngIf="isMobile">
        <div *ngFor="let examen of filteredExamenes | paginate: currentPage : pageSize" class="accordion">
            <div class="accordion-header" (click)="toggleExpand(examen)">
                <span>{{examen.nombreCurso}}</span>
                <button class="btn btn-sm btn-secondary">
                    <i [class]="examen.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
                </button>
            </div>
            <div class="accordion-content" *ngIf="examen.isExpanded">
                <strong>Fecha Inicio:
                </strong>{{dateService.formatearSoloFecha(examen.fechaInicio)}}<br>
                <strong>Fecha de Conclusión:
                </strong>{{dateService.formatearSoloFecha(examen.fechaFin)}}<br>
                <strong>Total Encuesta:</strong>
{{roundNumber(examen.encuesta)}}
            </div>
        </div>
        <span style="margin-top: 10px;" *ngIf="filteredExamenes.length === 0">Actualmente no tienes examenes</span>
    </div>
      <app-paginacion *ngIf="filteredExamenes.length >= pageSize" [paginaActual]="currentPage"
        [elementosPorPagina]="pageSize" [totalElemetos]="filteredExamenes.length" (cambioPagina)="onPageChange($event)">
      </app-paginacion>
    </div>
  </div>
</div>

