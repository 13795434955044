import { Component, OnInit } from '@angular/core';
import { ExamenesService } from 'src/app/service/examenes.service';
import { MisEstadisticasComponent } from "./mis-estadisticas/mis-estadisticas.component";
import { CategoriasComponent } from './categorias/categorias.component';

@Component({
  selector: 'app-examenes-alumno',
  standalone: true,
  imports: [MisEstadisticasComponent, CategoriasComponent],
  templateUrl: './examenes-alumno.component.html',
  styleUrl: './examenes-alumno.component.scss'
})
export class ExamenesAlumnoComponent implements OnInit{


  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

 
  
}
