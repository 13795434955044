import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ExamenesService } from 'src/app/service/examenes.service';
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DynamicModalComponent } from "../../../../common/dynamic-modal/dynamic-modal.component";
@Component({
  selector: 'app-subtemas',
  standalone: true,
  imports: [SidebarMenuComponent, ExamenesAdminComponent, DynamicModalComponent],
  templateUrl: './subtemas.component.html',
  styleUrl: './subtemas.component.scss',
  providers:[BsModalService, ToastrService]
})
export class SubtemasComponent {

  columns = [
    { key: 'subjectName', title: 'Materia' },
    { key: 'parentId', title: 'Padre' },
    { key: 'topicName', title: 'Tema(Id)' },
    { key: 'description', title: 'Descripcion' },
    { key: 'actions', title: 'Acciones', type: 'actions', buttonOne: null, buttonTwo: 'Editar' }
  ];

  parentOptions = [{
    value: 0,
    label: 'Parent'
  }]

  temas: any[] = []
  materias: any[] = []
  originalTemas: any[] = []
  currentPage:number = 1
  searchTerm:string = ''
  temasPorMateria:any[] = []

   //MODAL
   modalFields: any[] = [];
   initialData: any = null;
   modalTitle = '';
   @ViewChild('dynamicModal') dynamicModalTemplate: TemplateRef<any>;
   idTemaGuardar:number
   private modalRef: BsModalRef

  constructor(private examenesService: ExamenesService, private modalService: BsModalService,
    private toastrService: ToastrService){
  }

  ngOnInit(): void {
  this.obtenerTemas()
  this.obtenerMateriasSelect()
  }
  
  // Método para manejar la apertura y cierre del menú
  onMenuToggle(isOpen: boolean) {
    if (isOpen) {
      console.log('El menú está abierto');
      // Lógica para manejar cuando el menú se abre
    } else {
      console.log('El menú está cerrado');
      // Lógica para manejar cuando el menú se cierra
    }
  }

  obtenerTemas() {
    this.examenesService.obtenerTemas().subscribe(
      (response: any[] = []) => {
        console.log(response, 'temas');
        // Mapeamos los temas para agregar la propiedad 'topicName' concatenada con el 'id'
        this.temas = response.map(tem => ({
          ...tem, // Mantiene todas las propiedades originales
          topicName: tem.topicName 
              ? `${tem.topicName} (${tem.id})`
              : `Sin tema (${tem.id})`,
          subjectName: tem.subject?.name 
              ? tem.subject.name
              : `Sin materia`
      }));      
        this.originalTemas = [...this.temas]; // Copiamos la lista de temas original
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }  

  obtenerMateriasSelect() {
    this.examenesService.obtenerPreguntasTabla().subscribe(
      (response: any[] = []) => {
        console.log(response, 'materias')
        this.materias = response
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  obtenerTemasPorMateria(subjectId: any) {
    console.log(subjectId, 'identro');
    
    if (subjectId) {
        this.examenesService.obtenerTemasPorMateria(parseInt(subjectId.selectedValue? 
          subjectId.selectedValue : subjectId)).subscribe(
            (response: any[] = []) => {
                this.temasPorMateria = response;
                console.log(this.temasPorMateria, 'temas');

                // Actualizar las opciones de temas en el modal
                const padreField = this.modalFields.find(field => field.name === 'padreId');
                if (padreField) {
                    let totalOptions: any[] = this.parentOptions.concat(this.temasPorMateria.map(option => ({
                      value: option.id, 
                      label: option.topicName
                  })))
                    padreField.options = totalOptions
                }
            },
            (error: any) => {
                console.error('Error obteniendo temas por materia', error);
            }
        );
    }
}


  guardarTema(datos: any) {
    const request: any = {
      topicName: datos.nombre, // Aquí solo guardas el nombre limpio
      description: datos.descripcion,
      id: this.idTemaGuardar ? this.idTemaGuardar : null,
      subjectId: datos.materiaId,
      parentId: datos.padreId
    };
    
    this.examenesService.guardarTema(request).subscribe(
      (result: any) => {
        console.log(result);
        this.toastrService.success("Tema guardado correctamente!", "Éxito");
        this.obtenerTemas(); // Aquí se volverá a concatenar el ID
        this.cerrarModal();
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
        this.toastrService.error("No se pudo guardar el tema", "Error");
      }
    );
  }
  
  filterTemas(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.toLowerCase();

    this.temas = this.originalTemas.filter(tem =>
      tem.subjectName.toLowerCase().includes(this.searchTerm)
    );
    this.currentPage = 1; // Resetear a la primera página después de filtrar
  }

  abrirModal(datos: any) {
    this.idTemaGuardar = datos ? datos.id : null;
    this.modalTitle = datos ? 'Editar Tema' : 'Agregar Tema';
    if(datos && datos.subject.id){
      this.obtenerTemasPorMateria(datos.subject.id)
    }
    // Limpiar el ID de 'topicName' si existe
    const cleanTopicName = datos ? datos.topicName.replace(/\s*\(\d+\)$/, '') : '';
    this.modalFields = [
      {
        name: 'materiaId', type: 'select', label: 'Materia*',
        options: this.materias.map(option => ({ value: option.id, label: option.name })),change:true,
        required: true, errorMessage: 'Materia es obligatorio.', value: datos ? datos.subject.id : null
      },
      {
        name: 'padreId', type: 'select', label: 'Seleccionar padre*', 
        required: true, errorMessage: 'El padre es obligatorio.', value: datos ? datos.parentId : null
      },
      {
        name: 'nombre', type: 'text', label: 'Nombre del tema*',
        required: true, errorMessage: 'El Nombre del tema es obligatorio.', value: cleanTopicName
      },
      {
        name: 'descripcion', type: 'text', label: 'Descripcion*',
        required: true, errorMessage: 'Descripcion es obligatorio.', value: datos ? datos.description : null
      }
    ];
    this.initialData = datos ? this.modalFields : null;
    this.modalRef = this.modalService.show(this.dynamicModalTemplate);
  }
  

  cerrarModal() {
    this.modalRef.hide()
  }
}
