import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InicioService {

    private urlApi: string = `${environment.baseApi}dashboard`;
    private urlApiNormal: string = `${environment.baseApi}`;
    constructor(private http: HttpClient, private router: Router) { }

    obtenerDashboardAdministrador(){
        const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
        return this.http.get(this.urlApi+ "/administrador",{headers});
    }

    obtenerTablaInst(idInstructor:any){
        const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
        return this.http.get(this.urlApiNormal+ "examenes/"+idInstructor,{headers});
    }

    obtenerDashboardInstructor(idInstructor:any){
        const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
        return this.http.get(this.urlApi+ "/instructor/"+idInstructor,{headers});
    }

    ultimoCursoInscrito(idAlumno:any){
        const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
        return this.http.get(this.urlApiNormal+ "inscripciones/alumno/ultimo-inscrito/"+idAlumno,{headers});
      }
    
      ultimoCursoTerminado(idAlumno:any){
        const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
        return this.http.get(this.urlApiNormal+ "inscripciones/alumno/ultimo-terminado/"+idAlumno,{headers});
      }
}