<app-sidebar-menu></app-sidebar-menu>

<div class="cart-area ptb-100">
  <div class="container" *ngIf="rolUsuario === 'student'">
    <div class="header">
      <div class="title-container">
        <h3>Mis estadisticas</h3>
      </div>
      <div class="controls-container">
        <!-- Botones de alternancia -->
        <div class="order-buttons-container">
          <div class="btn-group" role="group" aria-label="Toggle buttons">
            <button type="button" class="btn" [ngClass]="activeTab === 'examen' ? 'btn-primary' : 'btn-secondary'"
              (click)="toggleView('examen')">
              Por Examen
            </button>
            <button type="button" class="btn" [ngClass]="activeTab === 'historia' ? 'btn-primary' : 'btn-secondary'"
              (click)="toggleView('historia')">
              Historia
            </button>
          </div>
        </div>
        <div class="search-container">
          <input type="text" placeholder="Buscar aqui..." (keyup)="filterEstadisticas($event)" class="input-search">
        </div>
        <div class="registers-container">
          <span> Mostrar </span>
          <select id="pageSizeSelect" (change)="changePageSize($event)">
            <option value="5">5</option>
            <option value="10" selected>10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <span> Registros </span>
        </div>
      </div>
    </div>

    <!-- Tabla por Examen -->
    <div *ngIf="activeTab === 'examen' && !isMobile" class="recent-orders-table">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Titulo
              <app-sorting-buttons [prop]="'title'"
              (sortEvent)="sortEstadisticas($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Categoria
              <app-sorting-buttons [prop]="'category'"
              (sortEvent)="sortEstadisticas($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Curso
              <app-sorting-buttons [prop]="'nombreCurso'"
              (sortEvent)="sortEstadisticas($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Duracion
              <app-sorting-buttons [prop]="'duration'"
              (sortEvent)="sortEstadisticas($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Numero de aciertos
              <app-sorting-buttons [prop]="'marksObtained'"
              (sortEvent)="sortEstadisticas($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Calificacion
              <app-sorting-buttons [prop]="'percentage'"
              (sortEvent)="sortEstadisticas($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Intentos
              <app-sorting-buttons [prop]="'attempts'"
              (sortEvent)="sortEstadisticas($event.prop, $event.order)"></app-sorting-buttons>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of estadisticasPorExamen | paginate: currentPage : pageSize">
            <td>{{item.title}}</td>
            <td>{{item.category}}</td>
            <td>{{item.nombreCurso}}</td>
            <td>{{item.duration + ' Minutos'}}</td>
            <td>{{item.marksObtained}}</td>
            <td>{{item.percentage}}</td>
            <td>{{item.attempts}}</td>
          </tr>
        </tbody>
      </table>
    </div>
 <!-- Estructura para dispositivos móviles por examen-->
 <div *ngIf="activeTab === 'examen' && isMobile">
  <div *ngFor="let itemMovil of estadisticasPorExamen | paginate: currentPage : pageSize" class="accordion">
      <div class="accordion-header" (click)="toggleExpand(itemMovil)">
          <span>{{itemMovil.nombreCurso}}</span>
          <button class="btn btn-sm btn-secondary">
              <i [class]="itemMovil.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
          </button>
      </div>
      <div class="accordion-content" *ngIf="itemMovil.isExpanded">
          <strong>Categoria:</strong>{{(itemMovil.category)}}<br>
          <strong>Curso:</strong>{{(itemMovil.nombreCurso)}}<br>
          <strong>Duracion:</strong>{{itemMovil.duration + ' Minutos'}}<br>
          <strong>Numero de aciertos:</strong>{{itemMovil.marksObtained}}<br>
          <strong>Calificacion:</strong>{{itemMovil.percentage}}<br>
          <strong>Intentos:</strong>{{itemMovil.attempts}}<br>
      </div>
  </div>
  <span style="margin-top: 10px;" *ngIf="estadisticasPorExamen.length === 0">Actualmente no tienes examenes</span>
</div>

    <!-- Tabla Historia -->
    <div *ngIf="activeTab === 'historia' && !isMobile" class="recent-orders-table">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Titulo
              <app-sorting-buttons [prop]="'title'"
              (sortEvent)="sortEstadisticas($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Resultados</th>
            <th>Aciertos
              <app-sorting-buttons [prop]="'aciertos'"
              (sortEvent)="sortEstadisticas($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Resultado
              <app-sorting-buttons [prop]="'exam_status'"
              (sortEvent)="sortEstadisticas($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Generar Certificado</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of estadisticasHistoria | paginate: currentPage : pageSize">
            <td><label class="buttons-label" (click)="verAnalisis(item)">{{item.title}}</label></td>
            <td><label [ngClass]="{'buttons-label' : item.exam_status === 'pass'}" (click)="verDetalle(item)">Ver Detalle</label></td>
            <td>{{item.aciertos}}</td>
            <td>
              <button class="btn btn-sm btn-success" *ngIf="item.exam_status === 'pass'" [disabled]="true">
                Aprobado
              </button>

              <button class="btn btn-sm btn-danger" *ngIf="item.exam_status === 'fail'" [disabled]="true">
                No aprobado
              </button>
            </td>
            <td><label [ngClass]="{'buttons-label' : item.exam_status === 'pass'}" (click)="generarCertificado(item)">Generar certificado</label></td>
          </tr>
        </tbody>
      </table>
    </div>
     <!-- Estructura para dispositivos móviles por historia-->
     <div *ngIf="activeTab === 'historia' && isMobile">
      <div *ngFor="let itemMovil of estadisticasHistoria | paginate: currentPage : pageSize" class="accordion">
          <div class="accordion-header" (click)="toggleExpand(itemMovil)">
              <span>{{itemMovil.title}}</span>
              <button class="btn btn-sm btn-secondary">
                  <i [class]="itemMovil.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
              </button>
          </div>
          <div class="accordion-content" *ngIf="itemMovil.isExpanded">
              <strong>Resultados:</strong><label class="buttons-label" (click)="verDetalle(itemMovil.slug)">Ver Detalle</label><br>
              <strong>Aciertos:</strong>{{(itemMovil.aciertos)}}<br>
              <strong>Resultado:</strong>{{itemMovil.exam_status === 'pass'? 'Aprobado' : 'No aprobado'}}<br>
              <strong>Generar certificado:</strong><label class="buttons-label" (click)="generarCertificado(itemMovil.slug)">Generar certificado</label><br>
          </div>
      </div>
      <span style="margin-top: 10px;" *ngIf="estadisticasHistoria.length === 0">Actualmente no tienes examenes</span>
  </div>

    <!-- Paginación -->
    <app-paginacion *ngIf="getCurrentData().length >= pageSize" [paginaActual]="currentPage"
      [elementosPorPagina]="pageSize" [totalElemetos]="getCurrentData().length" (cambioPagina)="onPageChange($event)">
    </app-paginacion>
    <div class="charts-container">
      <div class="chart" *ngIf="activeTab === 'examen'">
        <canvas id="intentosChart"></canvas>
      </div>
      <div class="chart-history" *ngIf="activeTab === 'historia'">
        <canvas id="historiaChart"></canvas>
      </div>
    </div>
  </div>
</div>
