import { Component, OnInit, HostListener } from '@angular/core';
import { ThemeCustomizerService } from '../theme-customizer/theme-customizer.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule, NgClass } from '@angular/common';
import { IniciarSesionService } from 'src/app/service/iniciar-sesion.service';
import { NotificacionesService } from 'src/app/service/notificaciones.service';

@Component({
    selector: 'app-header-style-one',
    standalone: true,
    imports: [RouterLinkActive, NgClass, RouterLink, CommonModule],
    templateUrl: './header-style-one.component.html',
    styleUrls: ['./header-style-one.component.scss']
})
export class HeaderStyleOneComponent implements OnInit {

    isSticky: boolean = false;
    isNotificationDropdownOpen: boolean = false
    isUserDropdownOpen: boolean = false
    notificacionesNuevas: number = 0;
    notificaciones:any[] = [];
    nombreUsuario: string;
    rolUsuario:string;
    showAllNotifications: boolean = false;
    notificacionesToShow: any[] = [];
    isMobile: boolean

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    isToggled = false;

    constructor(
        public themeService: ThemeCustomizerService, private router: Router,
        private iniciarSesionService: IniciarSesionService, private notificacionesService: NotificacionesService
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.isMobile = window.innerWidth < 576;
        window.onresize = () => {
          this.isMobile = window.innerWidth < 576;
          
        };
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(): void {
        this.nombreUsuario = localStorage.getItem('nombreUsuario');
		this.rolUsuario = localStorage.getItem('rolUsuario');
        this.consultarUltimasNotificaciones()
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    toggleNotificationDropdown(): void {
        this.isNotificationDropdownOpen = !this.isNotificationDropdownOpen;
        if(this.isUserDropdownOpen){
            this.isUserDropdownOpen = false
        }
    }
    
    toggleUserDropdown(): void {
        this.isUserDropdownOpen = !this.isUserDropdownOpen;
        if(this.isNotificationDropdownOpen){
            this.isNotificationDropdownOpen = false
        }
    }  

    cerrarSesison(){
        this.iniciarSesionService.logout();
    }

    consultarUltimasNotificaciones() {
        this.notificacionesService.obtenerUltimasNotificaciones().subscribe(notificaciones => {
            this.notificaciones = notificaciones || [];
            this.actualizarNotificacionesToShow();
            // Cuenta cuantas notificaciones no han sido vistas
            this.notificacionesNuevas = this.notificaciones.filter(notificacion => !notificacion.vista).length;
        }, error => {
            // Manejar error
        });
    }
    
    marcarComoLeido(notificacion: any) {
        if (!notificacion.vista) {
            this.notificacionesNuevas -= 1;
            notificacion.vista = true;
            var jsonData = JSON.stringify({ "idNotificacionVista": notificacion.idNotificacionVista });
            this.notificacionesService.verNotificacion(jsonData).subscribe(ver => {
                if (ver) {
                    // Actualizar UI o realizar acción adicional si es necesario
                } else {
                    // Manejar error
                }
            }, error => {
                // Manejar error
            });
        }
    }
    
    redirigirANotificacion(link: string) {
        this.router.navigate([link]);
    }
    
    verMasNotificaciones(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.showAllNotifications = !this.showAllNotifications;
        this.actualizarNotificacionesToShow();
    }
    
    actualizarNotificacionesToShow() {
        this.notificacionesToShow = this.showAllNotifications ? this.notificaciones : this.notificaciones.slice(0, 3);
    }
}
